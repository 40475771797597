import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { Table, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import { CaretRightOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import store from "../../../store";

import MQTTService from "../../../services/mqtt";

import { selectors as NODE_SELECTORS, actions as NODE_ACTIONS } from "../../../ducks/node";

import { MESSAGE_TYPE } from "../../../lib/utils/constants";
import themeColor from "../../../lib/style/theme";

import SearchFilter from "../../../components/Filters/SearchFilter";
import NodePortsTypes from "../../../components/NodePortsTypes";

const NodePorts = ({ activeLink, selectedCwid, selectedCnn }) => {
  const { cwid: cwidParam, cnn: cnnParam } = useParams();
  const cwid = cwidParam || selectedCwid;
  const cnn = cnnParam || selectedCnn;

  const nodePorts = useSelector(NODE_SELECTORS.getNodePortsList);

  useEffect(() => {
    if (cwid) {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.GET_ALL_PORTS,
        },
      });
    }
  }, [cwid]);

  const columns = [
    {
      title: <FormattedMessage id="NodePorts.port" defaultMessage="Port" />,
      dataIndex: "port",
      key: "port",
      width: 90,
      ...SearchFilter({ dataIndex: "port", placeholder: "port" }),
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.port - b.port,
    },
    {
      title: <FormattedMessage id="NodePorts.where" defaultMessage="Where" />,
      dataIndex: "type",
      width: 90,
      render: (type) => <NodePortsTypes type={type} />,
    },
    {
      title: <FormattedMessage id="NodePorts.path" defaultMessage="Path" />,
      dataIndex: "path",
      ...SearchFilter({ dataIndex: "path", placeholder: "path", cnn, cwid }),
      render: (path, record) => {
        if (activeLink) {
          return (
            <Tooltip
              placement="right"
              title={<FormattedMessage id="NodePorts.gotToChannelsDetail" defaultMessage="Got to channel detail" />}
            >
              <Link
                key={record?.id}
                to={`/node/${cwid}/${cnn}/channel/${record?.channelId}`}
                onClick={() => store.dispatch(NODE_ACTIONS.SET_NODE_DRAWER(false))}
              >
                {path.map((outputName, index) => {
                  if (index === path.length - 1) {
                    return outputName;
                  }

                  return (
                    <span key={outputName}>
                      {outputName}
                      <StyledCaretRightOutlined />
                    </span>
                  );
                })}
              </Link>
            </Tooltip>
          );
        }

        return path.map((outputName, index) => {
          if (index === path.length - 1) {
            return outputName;
          }

          return (
            <span key={outputName}>
              {outputName}
              <StyledCaretRightOutlined />
            </span>
          );
        });
      },
    },
  ];

  return (
    <StyledTable
      columns={columns}
      dataSource={nodePorts}
      bordered
      role="table"
      rowKey={(record) => record.id}
      scroll={{ x: "calc(100vh - 250px)" }}
    />
  );
};

const StyledCaretRightOutlined = styled(CaretRightOutlined)`
  color: ${themeColor.orange};
`;

const StyledTable = styled(Table)`
  .ant-pagination-item {
    background: ${themeColor.orange};

    a {
      color: white;
    }
  }

  .ant-pagination-item-active {
    a {
      color: black;
    }
    border: none;
  }
`;

NodePorts.propTypes = {
  activeLink: PropTypes.bool.isRequired,
};

export default NodePorts;
