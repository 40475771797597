import React from "react";
import { Select } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { selectors as CHANNEL_SELECTORS, getSortedChannelsIdsSelector } from "../../ducks/nodeChannels";

const { Option } = Select;

const SelectEngineOption = ({ channelId, handleSelectChange, logList }) => {
  const channels = useSelector(CHANNEL_SELECTORS.getChannels);
  const sortedChannelsIds = useSelector(getSortedChannelsIdsSelector);

  const noData = sortedChannelsIds.length === 0;
  const parsedChannelsId = logList ? ["-1", "0", ...sortedChannelsIds] : sortedChannelsIds;

  const selectChannelName = (id) => {
    switch (id) {
      case "-1":
        return <FormattedMessage id="Logs.all" defaultMessage="All" />;
      case "0":
        return <FormattedMessage id="Logs.node" defaultMessage="Node" />;
      default:
        return channels.get(`${id}`).config?.name;
    }
  };

  return (
    <Select
      autoFocus
      showSearch
      optionFilterProp="children"
      style={{ width: 300 }}
      onChange={handleSelectChange}
      value={(noData && "No Data") || channelId}
      dropdownStyle={{ minWidth: "30%" }}
      disabled={noData}
    >
      {parsedChannelsId.map((id) => {
        return (
          <Option value={id} key={id}>
            {selectChannelName(id)}
          </Option>
        );
      })}
    </Select>
  );
};

SelectEngineOption.propTypes = {
  channelId: PropTypes.string,
  handleSelectChange: PropTypes.func.isRequired,
  logList: PropTypes.bool,
};

SelectEngineOption.defaultProps = {
  logList: false,
  channelId: null,
};

export default SelectEngineOption;
