/* eslint-disable max-len */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { defineMessages, useIntl } from "react-intl";
import { Row, Col, Divider, Card, Form } from "antd";
import styled from "styled-components";
import { connect, useSelector } from "react-redux";
import ImmutablePropTypes from "react-immutable-proptypes";

import { selectors as NODE_SELECTORS } from "../../../ducks/node";
import { selectors as CHANNEL_SELECTORS } from "../../../ducks/nodeChannels";
import { selectors as CLOUD_CHANNELS_SELECTORS } from "../../../ducks/cloudChannels";

import CloudChannelsServices from "../../../services/cloudChannels";

import {
  CONNECTION_METHOD_OUTPUT,
  DEMO_CONNECTION_METHOD,
  OUTPUTS_TYPE,
  QUICKSTREAM_METHOD_V2,
} from "../../../lib/utils/constants";
import { max32characters, az09space, required } from "../../../lib/utils/formValidation";
import { OUTPUT_TYPE } from "../../../lib/utils/types/outputURLTypes";
import { errorNotification } from "../../../lib/utils/notification";

import OutputConnectionMethodV5 from "../../Fields/OutputConnectionMethodV5";
import OutputStreamUdpSection from "../OutputStreamSection/OutputStreamUdpSection";
import OutputStreamSrtSection from "../OutputStreamSection/OutputStreamSrtSection";
import OutputStreamQSSection from "../../InputStreamQSSection";
import InputTextFiled from "../../Fields/InputTextField";
import InputNumberField from "../../Fields/InputNumberField";
import DemoConnectionMethod from "../../DemoConnectionMethod";
import RemoveButton from "../../RemoveButton";
import CloudChannelListV5 from "../../Fields/CloudChannelListV5";
import { PortField } from "../../Fields/Fields";
import LocalPortLabel from "../../LocalPortLabel";
import AdvancedSettings from "../../AdvancedSettings";

const SRT_METHODS = [CONNECTION_METHOD_OUTPUT.outSrtCaller.value, CONNECTION_METHOD_OUTPUT.outSrtListener.value];
const UDP_METHODS = [CONNECTION_METHOD_OUTPUT.outUdp.value];

const translations = defineMessages({
  cloudIdLabel: {
    id: "OutputFormItem.cloudIdLabel",
    defaultMessage: "Cloud ID",
  },
  outputName: {
    id: "OutputFormItem.outputName",
    defaultMessage: "Output name",
  },
  destinationName: {
    id: "OutputFormItem.destinationName",
    defaultMessage: "Destination name",
  },
  cloudName: {
    id: "OutputFormItem.cloudName",
    defaultMessage: "Cloud name",
  },
  outputSection: {
    id: "OutputFormItem.outputSection",
    defaultMessage: "Output",
  },
  auto: {
    id: "general.auto",
    defaultMessage: "Auto",
  },
  automatic: {
    id: "general.automatic",
    defaultMessage: "Automatic",
  },
  confirmRemoveText: {
    id: "OutputFormItem.confirmRemove",
    defaultMessage: "Are you sure, you want to remove this output?",
  },
  nameAlreadyExist: {
    id: "OutputFormItem.nameAlreadyExist",
    defaultMessage: "This output name already exist",
  },
});

function OutputFormItem({
  channelId,
  disabled,
  handleRemove,
  index,
  initialValue,
  name,
  nodeChannels,
  outputType,
  prefix,
  setDisableCondition,
  stundAddress,
  form,
  basicMode,
}) {
  const { formatMessage } = useIntl();
  const formConnectionMethod = Form.useWatch([...prefix, index, "type"], form);

  const { getFieldValue, setFieldsValue } = form;
  const ownChannelList = useSelector(CLOUD_CHANNELS_SELECTORS.getOwnChannels);

  useEffect(() => {
    CloudChannelsServices.getOwnChannels({ errorNotification: errorNotification(formatMessage) });
    CloudChannelsServices.getSharedChannels({ errorNotification: errorNotification(formatMessage) });
  }, [formatMessage]);

  const DEMO_CONNECTION_METHOD_VALUE_ARRAY = DEMO_CONNECTION_METHOD.map((method) => method.value);
  const isSRTMethod = SRT_METHODS.includes(formConnectionMethod);
  const isUDPMethod = UDP_METHODS.includes(formConnectionMethod);
  const isDemoConnectionMethod = DEMO_CONNECTION_METHOD_VALUE_ARRAY.includes(formConnectionMethod);
  const selectedConnectionMethodLabel = DEMO_CONNECTION_METHOD.find((method) => method.value === formConnectionMethod);
  const isQuickstreamMethod = formConnectionMethod === QUICKSTREAM_METHOD_V2.outQSDirect.value;

  const handleChangeConnectionMethod = (value) => {
    const idDemoConnectionMethodSelected = Object.values(value).includes(DEMO_CONNECTION_METHOD[0].value);
    setDisableCondition(idDemoConnectionMethodSelected);
  };

  const uniqueValidator = (rule, value) => {
    const outputNames = nodeChannels.getIn([`${channelId}`, "outputs", "outputsNames"]);

    const parsedOutputNames = outputNames && outputNames.filter((outName) => outName !== initialValue?.outputName);
    const duplicatedDirectOutputName = parsedOutputNames && parsedOutputNames.includes(value);

    if (duplicatedDirectOutputName) {
      return Promise.reject(formatMessage(translations.nameAlreadyExist));
    }

    return Promise.resolve();
  };

  return (
    <StyledCard
      title={initialValue && initialValue?.outputName}
      extra={
        name &&
        !disabled && (
          <RemoveButton
            handleRemove={handleRemove}
            fieldName={name}
            confirmRemoveText={formatMessage(translations.confirmRemoveText)}
          />
        )
      }
    >
      <Row type="flex" justify="start" gutter={24}>
        <Col span={12}>
          <OutputConnectionMethodV5
            name={[...prefix, index, "type"]}
            disabled={disabled}
            connectionMethod={formConnectionMethod}
            setConnectionMethod={handleChangeConnectionMethod}
            isTranscodingOutput={outputType === OUTPUTS_TYPE.TRANSCODING}
            isDirectOutput={outputType === OUTPUTS_TYPE.DIRECT}
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
            stundAddress={stundAddress}
            prefix={[...prefix, index, "type"]}
          />
        </Col>
        <Col span={12}>
          <InputTextFiled
            disabled={disabled}
            name={[...prefix, index, "outputName"]}
            label={formatMessage(translations.destinationName)}
            rules={[{ validator: uniqueValidator }, max32characters, az09space, required]}
          />
        </Col>
        {initialValue?.outputId && (
          <Col span={4}>
            <InputNumberField name={[...prefix, index, "outputId"]} label="outputId" disabled={disabled} hidden />
          </Col>
        )}
        <Divider />
        {isDemoConnectionMethod && <DemoConnectionMethod label={selectedConnectionMethodLabel.label} />}
        {isQuickstreamMethod && (
          <>
            <Col span={16}>
              <CloudChannelListV5
                channelList={ownChannelList}
                disabled={disabled}
                getFieldValue={getFieldValue}
                initialValue={
                  initialValue?.cloudId && initialValue?.cloudName
                    ? {
                        cloudId: initialValue?.cloudId,
                        channelName: initialValue?.cloudName,
                        permissionId: initialValue?.permissionId,
                        description: initialValue?.description,
                      }
                    : null
                }
                name={[...prefix, index]}
                label={formatMessage(translations.cloudIdLabel)}
                sameAsValidation="input"
                setFieldsValue={setFieldsValue}
                isTranscodingOutput={outputType === OUTPUTS_TYPE.TRANSCODING}
                isDirectOutput={outputType === OUTPUTS_TYPE.DIRECT}
              />
            </Col>
            <Col span={8}>
              <PortField
                name={[...prefix, index, "localPort"]}
                disabled={disabled}
                label={<LocalPortLabel noTooltip />}
                min={1}
              />
            </Col>
            <Col span={4} hidden>
              <InputTextFiled
                label={formatMessage(translations.cloudName)}
                name={[...prefix, index, "cloudName"]}
                hidden
                disabled={disabled}
              />
            </Col>
          </>
        )}
        {isSRTMethod && (
          <OutputStreamSrtSection
            disabled={disabled}
            name={[index]}
            prefix={[...prefix, index]}
            connectionMethod={formConnectionMethod}
            initialValue={initialValue}
            setFieldsValue={setFieldsValue}
            getFieldValue={getFieldValue}
            form={form}
            basicMode={basicMode}
          />
        )}
        {isUDPMethod && (
          <OutputStreamUdpSection
            prefix={[...prefix, index]}
            initialValue={initialValue}
            disabled={disabled}
            basicMode={basicMode}
          />
        )}
        {isQuickstreamMethod && (
          <Col span={24} hidden={basicMode}>
            <AdvancedSettings>
              <OutputStreamQSSection
                disabled={disabled}
                name={[...prefix, index]}
                prefix={[...prefix, index]}
                initialValue={initialValue}
                setFieldsValue={setFieldsValue}
                getFieldValue={getFieldValue}
                isTranscodingOutput={outputType === OUTPUTS_TYPE.TRANSCODING}
                isDirectOutput={outputType === OUTPUTS_TYPE.DIRECT}
                form={form}
                basicMode={basicMode}
              />
            </AdvancedSettings>
          </Col>
        )}
      </Row>
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  margin-top: 10px;
`;

OutputFormItem.propTypes = {
  channelId: PropTypes.string,
  disabled: PropTypes.bool,
  handleRemove: PropTypes.func,
  index: PropTypes.number.isRequired,
  initialValue: PropTypes.shape(OUTPUT_TYPE),
  name: PropTypes.string,
  nodeChannels: ImmutablePropTypes.map.isRequired,
  outputType: PropTypes.string,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  setDisableCondition: PropTypes.func.isRequired,
  stundAddress: PropTypes.string,
  form: PropTypes.object.isRequired,
  basicMode: PropTypes.bool,
};

OutputFormItem.defaultProps = {
  channelId: null,
  disabled: null,
  initialValue: null,
  name: null,
  outputType: null,
  stundAddress: null,
  basicMode: false,
  handleRemove: null,
};

const mapStateToProps = (state) => ({
  nodeChannels: CHANNEL_SELECTORS.getChannels(state),
  stundAddress: NODE_SELECTORS.getStundAddress(state),
});

export default connect(mapStateToProps, null)(OutputFormItem);
