import React from "react";
import PropTypes from "prop-types";
import { Select, Form } from "antd";

import ConnectionMethodLabel from "../NodeChannelFormV5/Labels/ConnectionMethodLabel";

import { CONNECTION_METHOD_INPUT, QUICKSTREAM_METHOD_V2 } from "../../lib/utils/constants";
import { required } from "../../lib/utils/formValidation";
import {
  DEFAULT_IN_UDP_UNICAST,
  DEFAULT_IN_UDP_MULTICAST,
  DEFAULT_IN_SRT_LISTENER,
  DEFAULT_IN_SRT_CALLER,
  DEFAULT_IN_QS_DIRECT,
} from "../../lib/utils/defaultValues/inputURLDefaultValues";

const { Item } = Form;
const { Option } = Select;

function InputTranscodingConnectionMethod({
  disabled,
  getFieldValue,
  isBackup,
  name,
  setConnectionMethod,
  setFieldsValue,
}) {
  const CONNECTION_METHOD_INPUT_ARRAY = Object.values({
    ...CONNECTION_METHOD_INPUT,
    ...(isBackup ? {} : { inQSDirect: QUICKSTREAM_METHOD_V2.inQSDirect }),
  });

  const getInputType = () => {
    if (isBackup) {
      return "backupInput";
    }

    return "mainInput";
  };

  const handleChangeConnectionMethod = (value) => {
    const inputType = getInputType();

    const inputFormData = getFieldValue([inputType]);

    switch (value) {
      case QUICKSTREAM_METHOD_V2.inQSDirect.value:
        delete inputFormData.inputStreamCfg;
        inputFormData.inputStreamCfg = DEFAULT_IN_QS_DIRECT;

        setFieldsValue({
          [`${inputType}`]: inputFormData,
        });

        break;
      case CONNECTION_METHOD_INPUT.inUdpUnicast.value:
        delete inputFormData.inputStreamCfg;
        inputFormData.inputStreamCfg = DEFAULT_IN_UDP_UNICAST;

        setFieldsValue({
          [`${inputType}`]: inputFormData,
        });
        break;
      case CONNECTION_METHOD_INPUT.inUdpMulticast.value:
        delete inputFormData.inputStreamCfg;
        inputFormData.inputStreamCfg = DEFAULT_IN_UDP_MULTICAST;

        setFieldsValue({
          [`${inputType}`]: inputFormData,
        });
        break;
      case CONNECTION_METHOD_INPUT.inSrtListener.value:
        delete inputFormData.inputStreamCfg;
        inputFormData.inputStreamCfg = DEFAULT_IN_SRT_LISTENER;

        setFieldsValue({
          [`${inputType}`]: inputFormData,
        });
        break;
      case CONNECTION_METHOD_INPUT.inSrtCaller.value:
        delete inputFormData.inputStreamCfg;
        inputFormData.inputStreamCfg = DEFAULT_IN_SRT_CALLER;

        setFieldsValue({
          [`${inputType}`]: inputFormData,
        });
        break;
      default:
        break;
    }

    setConnectionMethod(value);
  };

  return (
    <Item name={name} label={<ConnectionMethodLabel />} rules={[required]}>
      <Select disabled={disabled} onChange={handleChangeConnectionMethod}>
        {CONNECTION_METHOD_INPUT_ARRAY.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </Item>
  );
}

InputTranscodingConnectionMethod.propTypes = {
  disabled: PropTypes.bool,
  getFieldValue: PropTypes.func,
  isBackup: PropTypes.bool,
  name: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  setConnectionMethod: PropTypes.func,
  setFieldsValue: PropTypes.func,
};

InputTranscodingConnectionMethod.defaultProps = {
  disabled: false,
  getFieldValue: null,
  isBackup: false,
  setFieldsValue: null,
  setConnectionMethod: null,
};

export default InputTranscodingConnectionMethod;
