import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Input } from "antd";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";

import themeColor from "../../lib/style/theme";

const { Search } = Input;

const translations = defineMessages({
  search: {
    id: "general.search",
    defaultMessage: "Search",
  },
});

export default function SearchInputChannelsList() {
  const { formatMessage } = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();

  const search = searchParams.get("search") || "";

  const handleSearchChannels = (searchValue) => {
    searchParams.set("search", searchValue);
    setSearchParams(searchParams);
  };

  const handleChange = (updatedValue) => {
    if (updatedValue === "") {
      searchParams.delete("search");
      setSearchParams(searchParams);
    } else {
      searchParams.set("search", updatedValue);
      setSearchParams(searchParams);
    }
  };

  return (
    <StyledSearch
      placeholder={formatMessage(translations.search)}
      onSearch={handleSearchChannels}
      allowClear
      value={search}
      onChange={(event) => handleChange(event.target.value)}
    />
  );
}

const StyledSearch = styled(Search)`
  .anticon-close-circle {
    margin: 0 auto;
  }
  .ant-input-search-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${themeColor.orange};

    &:hover {
      background: ${themeColor.orangeHover} !important;
    }
  }
  .anticon-search {
    margin: 0 auto;
    color: #fff;
  }
`;
