import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Divider, Empty, Tabs, Card, Row, Col, Button, Popconfirm } from "antd";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import ImmutablePropTypes from "react-immutable-proptypes";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";

import CloudChannelsServices from "../../../services/cloudChannels";
import NODE_CHANNELS_SERVICES from "../../../services/node";
import MQTTService from "../../../services/mqtt";
import { selectors as NODE_LIVE_STATS_SELECTORS } from "../../../ducks/nodeLiveStats";
import { selectors as CHANNEL_SELECTORS } from "../../../ducks/nodeChannels";
import { selectors as CLOUD_CHANNELS_SELECTORS } from "../../../ducks/cloudChannels";

import SelectEngine from "../../../components/SelectEngine";
import StatisticsView from "./StatisticsView";
import StatisticsGraph from "./StatisticsGraph";

import StatisticOutput from "./StatisticOutput";
import StatisticsCodecs from "./StatisticsCodecs";

import { errorNotification } from "../../../lib/utils/notification";
import { CHANNEL_SOURCE, MESSAGE_TYPE, NODE_CHANNEL_STATE } from "../../../lib/utils/constants";
import globalTranslations from "../../../lib/translations";
import parseConnectionMethodName from "../../../lib/utils/parseConnectionMethodName";
import convertNodeStatsFormat from "../../../lib/utils/convertNodeStatsFormat";

const StatisticsLive = ({ channelDetailView, channelId, channels, cwid, permissions, setChannelId, statistics }) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    CloudChannelsServices.getOwnChannels({ errorNotification: errorNotification(formatMessage) });
    CloudChannelsServices.getSharedChannels({ errorNotification: errorNotification(formatMessage) });
  }, [formatMessage]);

  const clearLiveStats = () => {
    NODE_CHANNELS_SERVICES.clearStatistic({ channelId, from: cwid });
    MQTTService.sendMessage({
      topic: `node/${cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.RESET_LIVE_STATS,
        channelId,
      },
    });
  };

  const channelStatusText = channels.getIn([channelId, "status", "channelStatusText"]);
  const isChannelStarted = channelStatusText === NODE_CHANNEL_STATE.CONNECTED;

  const statisticExist = statistics && channelId in statistics;
  const statisticsData = statisticExist && statistics[channelId];

  const statisticsInput = statisticExist && statisticsData.map((stat) => stat.input);
  const statsChannel = statisticExist && statisticsData.map((stat) => stat.channel);
  const statsCodecs = statisticExist && statisticsData.map((stat) => stat.codecs);
  const newestChannelInputStats = statisticsData && statisticsData[statisticsData.length - 1].input;
  const newestChannelStats = statisticsData && statisticsData[statisticsData.length - 1].channel;
  const newestChannelCodecsStats = statisticsData && statisticsData[statisticsData.length - 1].codecs;

  const statisticsOutputArray =
    statisticExist &&
    statisticsData.map((stat) => {
      const parsedNonMuxedStat =
        (stat.nonMuxed && stat.nonMuxed.map((nonMuxedStat) => ({ ...nonMuxedStat, outputId: nonMuxedStat?.p2 }))) || [];
      const parsedMuxedStat =
        (stat.muxedUrl && stat.muxedUrl.map((muxedUrlStat) => ({ ...muxedUrlStat, outputId: muxedUrlStat?.p2 }))) || [];
      const parsedMuxedNonUrlStat =
        (stat.muxedNonUrl &&
          stat.muxedNonUrl.map((muxedNonUrlStat) => ({ ...muxedNonUrlStat, outputId: muxedNonUrlStat?.p1 }))) ||
        [];

      return [...parsedMuxedNonUrlStat, ...parsedMuxedStat, ...parsedNonMuxedStat];
    });
  const outputStatExist = statisticsOutputArray && statisticsOutputArray.length > 0;

  const parsedStatOutput =
    statisticsOutputArray &&
    statisticsOutputArray.map((statOutputData) => {
      const parsedStatOutputData =
        statOutputData &&
        statOutputData.map((statData) => {
          const { outputs } = channels.find((channel) => channel.channelId === channelId) || {
            status: {},
            outputs: [],
          };

          const outputConfig = outputs?.outputsConfigs.find((output) => +output.outputId === +statData.outputId);

          // check if UDP or SRT
          if (statData.p3 <= CHANNEL_SOURCE.nodeChannel) {
            return {
              ...statData,
              channelName: outputConfig?.outputName,
              username: `${parseConnectionMethodName(outputConfig?.type)}`,
            };
          }

          const permissionDataExist = !!permissions[channelId];

          if (permissionDataExist) {
            const permissionDescriptionArray = permissions[channelId];
            const descriptionData = permissionDescriptionArray.find(
              (permission) => +permission?.permissionId === +statData.p3
            );

            return { ...statData, channelName: descriptionData?.description, username: descriptionData?.username };
          }

          return { ...statData, channelName: `permissionId: ${statData.p3}`, permissionId: statData.p3 };
        });

      return parsedStatOutputData;
    });

  const handleSelectChange = (selectedEngineId) => {
    setChannelId(selectedEngineId);
  };

  const tabItems = [
    {
      label: <FormattedMessage id="StatisticsLive.input" defaultMessage="Input" />,
      key: "input",
      children: (
        <>
          <Row justify="start" gutter={24}>
            {newestChannelInputStats && (
              <StatisticsGraph
                data={statisticsInput}
                channelId={channelId}
                type="input"
                isChannelStarted={isChannelStarted}
              />
            )}
            {newestChannelInputStats && <StatisticsView data={convertNodeStatsFormat(newestChannelInputStats)} />}
          </Row>
          {!newestChannelInputStats && (
            <Empty
              description={
                <FormattedMessage id="StatisticsLive.waitingForStatistics" defaultMessage="Waiting for Statistics..." />
              }
            />
          )}
        </>
      ),
    },
    {
      label: <FormattedMessage id="StatisticsLive.outputs" defaultMessage="Outputs" />,
      key: "outputs",
      children: (
        <>
          {outputStatExist && (
            <StatisticOutput
              statistics={parsedStatOutput}
              channelId={channelId}
              type="outputs"
              isChannelStarted={isChannelStarted}
            />
          )}
          {!outputStatExist && (
            <Empty
              description={
                <FormattedMessage id="StatisticsLive.waitingForStatistics" defaultMessage="Waiting for Statistics..." />
              }
            />
          )}
        </>
      ),
    },
    {
      label: <FormattedMessage id="StatisticsLive.channel" defaultMessage="Channel" />,
      key: "channel",
      disabled: !newestChannelStats || (newestChannelStats && newestChannelStats.length < 1),
      children: (
        <Row justify="start" gutter={24}>
          {newestChannelStats && (
            <StatisticsGraph
              data={statsChannel}
              channelId={channelId}
              type="channel"
              isChannelStarted={isChannelStarted}
            />
          )}
          {newestChannelStats && <StatisticsView data={convertNodeStatsFormat(newestChannelStats)} />}
        </Row>
      ),
    },
    {
      label: <FormattedMessage id="StatisticsLive.codecs" defaultMessage="Codecs" />,
      key: "codecs",
      disabled: !newestChannelCodecsStats || (newestChannelCodecsStats && newestChannelCodecsStats.length < 1),
      children: (
        <StatisticsCodecs
          data={statsCodecs}
          channelId={channelId}
          newestChannelCodecsStats={newestChannelCodecsStats}
          type="codecs"
          isChannelStarted={isChannelStarted}
        />
      ),
    },
  ];

  return (
    <Card>
      <Row justify="space-between">
        {!channelDetailView && (
          <Col>
            <StyledRightMargin>
              <FormattedMessage id="StatisticsLive.channel" defaultMessage="Channel" />
              {": "}
            </StyledRightMargin>
            <SelectEngine channelId={channelId} handleSelectChange={handleSelectChange} />
          </Col>
        )}
        <Col>
          <Popconfirm
            placement="top"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            title={
              <FormattedMessage
                id="StatisticsLive.removeQuestion"
                defaultMessage="Are you sure that you want to clear all live data for this channel?"
              />
            }
            onConfirm={clearLiveStats}
            okText={formatMessage(globalTranslations.yes)}
            cancelText={formatMessage(globalTranslations.no)}
          >
            <Button type="primary" danger icon={<DeleteOutlined />}>
              <span>
                <FormattedMessage id="StatisticsLive.clearLiveStats" defaultMessage="Clear live data" />
              </span>
            </Button>
          </Popconfirm>
        </Col>
      </Row>

      <Divider />
      <Tabs defaultActiveKey="input" items={tabItems} />
    </Card>
  );
};

const StyledRightMargin = styled.span`
  margin-right: 5px;
`;

StatisticsLive.propTypes = {
  cwid: PropTypes.string,
  channels: ImmutablePropTypes.map.isRequired,
  channelId: PropTypes.string.isRequired,
  permissions: PropTypes.object,
  statistics: PropTypes.oneOfType([
    PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          ccErrors: PropTypes.string.isRequired,
          ccErrorsPerHour: PropTypes.string.isRequired,
          ccErrorsTotal: PropTypes.string.isRequired,
          byteAvailRcvBuf: PropTypes.string.isRequired,
          byteAvailSndBuf: PropTypes.string.isRequired,
          channelId: PropTypes.number.isRequired,
          mbpsBandwidth: PropTypes.string.isRequired,
          mbpsRecvRate: PropTypes.string.isRequired,
          mbpsSendRate: PropTypes.string.isRequired,
          msRtt: PropTypes.string.isRequired,
          pktCongestionWindow: PropTypes.string.isRequired,
          pktFlightSize: PropTypes.string.isRequired,
          pktFlowWindow: PropTypes.string.isRequired,
          pktRcvAvgBelatedTime: PropTypes.string.isRequired,
          pktRcvBelated: PropTypes.string.isRequired,
          pktRcvDrop: PropTypes.string.isRequired,
          pktRcvLoss: PropTypes.string.isRequired,
          pktRcvRetrans: PropTypes.string.isRequired,
          pktRecv: PropTypes.string.isRequired,
          pktReorderDistance: PropTypes.string.isRequired,
          pktRetrans: PropTypes.string.isRequired,
          pktSent: PropTypes.string.isRequired,
          pktSndDrop: PropTypes.string.isRequired,
          pktSndLoss: PropTypes.string.isRequired,
          time: PropTypes.string.isRequired,
        })
      )
    ),
    PropTypes.object,
  ]),
  setChannelId: PropTypes.func,
  channelDetailView: PropTypes.bool,
};

StatisticsLive.defaultProps = {
  cwid: null,
  permissions: {},
  statistics: {},
  setChannelId: null,
  channelDetailView: null,
};

const mapStateToProps = (state, ownProps) => ({
  channels: CHANNEL_SELECTORS.getChannels(state),
  statistics: NODE_LIVE_STATS_SELECTORS.getStatistics({ state, ownProps }),
  ownChannelsList: CLOUD_CHANNELS_SELECTORS.getOwnChannels(state),
  permissions: CLOUD_CHANNELS_SELECTORS.getChannelPermissions(state),
});

export default connect(mapStateToProps, null)(StatisticsLive);
