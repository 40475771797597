import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Row, Tooltip, Spin, Divider, Button } from "antd";
import { FormattedMessage } from "react-intl";
import { ifProp } from "styled-tools";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { LineChartOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import { selectors as STATISTIC_NODE_SELECTORS } from "../../ducks/nodeSystemStatistics";
import { selectors as CONNECTION_SELECTORS } from "../../ducks/node";

import themeColor from "../../lib/style/theme";

const NodeHeaderSystemStats = ({ size }) => {
  const { cwid, cnn } = useParams();

  const nodeVersion = useSelector(CONNECTION_SELECTORS.getNodeVersion);
  const statisticsNode = useSelector(STATISTIC_NODE_SELECTORS.getStatisticNode);
  const navigate = useNavigate();
  const filteredStatisticsNode = statisticsNode && statisticsNode[cwid];
  const newestStatisticNode = filteredStatisticsNode && filteredStatisticsNode[filteredStatisticsNode.length - 1];

  return (
    <Row align="middle">
      <Tooltip placement="top" title={<FormattedMessage id="NodeSubTitle.nodeVersion" defaultMessage="Node version" />}>
        <StyledConnectLabelSmall size={size}>
          <FormattedMessage id="general.node" defaultMessage="Node" />: {nodeVersion && <span>{nodeVersion}</span>}
          {!nodeVersion && (
            <span>
              <StyledSpin size="small" />
            </span>
          )}
        </StyledConnectLabelSmall>
      </Tooltip>
      <Tooltip placement="top" title={<FormattedMessage id="NodeSubTitle.cpuUsage" defaultMessage="CPU usage" />}>
        <StyledConnectLabelSmall size={size}>
          <FormattedMessage id="general.cpu" defaultMessage="CPU" />:{" "}
          {newestStatisticNode && <span>{newestStatisticNode.cpuLoad}%</span>}
          {!newestStatisticNode && (
            <span>
              <StyledSpin size="small" />
            </span>
          )}
          <Button
            type="link"
            size="small"
            shape="circle"
            icon={<LineChartOutlined />}
            onClick={() => navigate(`/node/${cwid}/${cnn}/system?tab=statistics`)}
          />
        </StyledConnectLabelSmall>
      </Tooltip>
      <Divider type="vertical" />
      <Tooltip placement="top" title={<FormattedMessage id="NodeSubTitle.ramUsage" defaultMessage="RAM usage" />}>
        <StyledConnectLabelSmall size={size}>
          <FormattedMessage id="general.ram" defaultMessage="RAM" />:{" "}
          {newestStatisticNode && (
            <span>{Math.round(100 - (newestStatisticNode?.ramAvailable * 100) / newestStatisticNode?.ramTotal)}%</span>
          )}
          {!newestStatisticNode && (
            <span>
              <StyledSpin size="small" />
            </span>
          )}
          <Button
            type="link"
            size="small"
            shape="circle"
            icon={<LineChartOutlined />}
            onClick={() => navigate(`/node/${cwid}/${cnn}/system?tab=statistics`)}
          />
        </StyledConnectLabelSmall>
      </Tooltip>
      <Divider type="vertical" />
      <Tooltip
        placement="top"
        title={
          <FormattedMessage id="NodeSubTitle.totalIncomingNetwork" defaultMessage="Total incoming network traffic" />
        }
      >
        <StyledConnectLabel size={size}>
          <FormattedMessage id="general.in" defaultMessage="In" />:{" "}
          {newestStatisticNode && <span>{newestStatisticNode.totalIncoming} [Mbps]</span>}
          {!newestStatisticNode && (
            <span>
              <StyledSpin size="small" />
            </span>
          )}
          <Button
            type="link"
            size="small"
            shape="circle"
            icon={<LineChartOutlined />}
            onClick={() => navigate(`/node/${cwid}/${cnn}/system?tab=statistics`)}
          />
        </StyledConnectLabel>
      </Tooltip>
      <Divider type="vertical" />
      <Tooltip
        placement="top"
        title={
          <FormattedMessage id="NodeSubTitle.totalOutgoingNetwork" defaultMessage="Total outgoing network traffic" />
        }
      >
        <StyledConnectLabel size={size}>
          <FormattedMessage id="general.out" defaultMessage="Out" />:{" "}
          {newestStatisticNode && <span>{newestStatisticNode.totalOutgoing} [Mbps]</span>}
          {!newestStatisticNode && (
            <span>
              <StyledSpin size="small" />
            </span>
          )}
          <Button
            type="link"
            size="small"
            shape="circle"
            icon={<LineChartOutlined />}
            onClick={() => navigate(`/node/${cwid}/${cnn}/system?tab=statistics`)}
          />
        </StyledConnectLabel>
      </Tooltip>
    </Row>
  );
};

NodeHeaderSystemStats.propTypes = {
  size: PropTypes.string,
};

NodeHeaderSystemStats.defaultProps = {
  size: null,
};

const StyledSpin = styled(Spin)`
  .ant-spin-dot-item {
    background-color: ${themeColor.maritime};
  }
`;

const StyledConnectLabel = styled.span`
  margin-right: 10px;
  font-size: ${ifProp({ size: "small" }, "14px", "16px")};
  min-width: ${ifProp({ size: "small" }, "90px", "120px")};

  span {
    color: ${themeColor.maritime};
  }
`;

const StyledConnectLabelSmall = styled(StyledConnectLabel)`
  min-width: ${ifProp({ size: "small" }, "60px", "90px")};
`;

export default NodeHeaderSystemStats;
