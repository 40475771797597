import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Row, Col, Modal, Button, Empty } from "antd";
import styled from "styled-components";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";

import parseConnectionMethodName from "../../../../lib/utils/parseConnectionMethodName";
import {
  OUTPUTS_TYPE,
  NEW,
  CONNECTION_METHOD_OUTPUT,
  DIRECT_ENGINE_OUTPUTS_PREFIX,
} from "../../../../lib/utils/constants";
import { OUTPUT_TYPE } from "../../../../lib/utils/types/outputURLTypes";
import defaultDirectOutput from "../../../../lib/utils/defaultValues/defaultDirectOutput";
import { warningNotification } from "../../../../lib/utils/notification";

import StreamSourceElement from "../../../StreamSourceElement";
import OutputFormItem from "../../OutputFormItem";
import { FormV5Context } from "../../NodeChannelFormV5";

const translations = defineMessages({
  formValidationError: {
    id: "general.formValidationError",
    defaultMessage: "Form validation error: Please check all fields",
  },
});

function EngineDirectOutputs({ channelId, detailView, outputs, handleFinish }) {
  const form = useContext(FormV5Context);
  const { setFieldsValue, resetFields, getFieldValue, validateFields } = form;

  const { formatMessage } = useIntl();
  const [outputSectionVisible, setOutputSectionVisible] = useState(null);
  const [disableCondition, setDisableCondition] = useState(false);
  const numberOfOutputs = outputs ? outputs.length : 0;

  const handleAddNewDirectOutput = () => {
    const formDirectOutputs = getFieldValue([DIRECT_ENGINE_OUTPUTS_PREFIX]) || [];

    const outputsNumber = formDirectOutputs.length + 1;
    setFieldsValue({
      outputs: [
        ...formDirectOutputs,
        {
          ...defaultDirectOutput(CONNECTION_METHOD_OUTPUT.outUdp.value),
          outputName: `Output-${outputsNumber}`,
        },
      ],
    });
    setOutputSectionVisible(NEW);
  };

  const handleOKDirectOutput = async () => {
    if (!disableCondition) {
      try {
        await validateFields();
        const updatedFormData = getFieldValue();
        handleFinish(updatedFormData);
        setOutputSectionVisible(null);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("Error - formValidation", error);
        warningNotification(formatMessage)(translations.formValidationError);
      }
    }
  };

  const handleCancelDirectOutput = () => {
    resetFields([DIRECT_ENGINE_OUTPUTS_PREFIX]);
    setOutputSectionVisible(null);
  };

  const handleRemoveDirect = (outputName) => {
    const channelConfig = getFieldValue();
    const parsedFormDirectOutputs = outputs.filter((output) => output.outputName !== outputName);
    const updatedFormData = {
      ...channelConfig,
      outputs: parsedFormDirectOutputs,
    };

    handleFinish(updatedFormData);
    resetFields();
    setFieldsValue({ outputs: parsedFormDirectOutputs });
    setOutputSectionVisible(null);
  };

  const isDirectOutputs = outputs && outputs.length > 0;

  if (!isDirectOutputs && detailView) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <>
      {outputs &&
        outputs.map((output, index) => {
          const handleClick = () => {
            setOutputSectionVisible(output.outputName);
          };

          return (
            <Row gutter={24} key={output.outputName}>
              <Col span={24}>
                <StreamSourceElement
                  connectionType={output?.type}
                  destinationAddress={output?.destinationAddress}
                  destinationPort={output?.destinationPort}
                  localPort={output?.localPort}
                  handleClick={handleClick}
                  interfaceIp={output?.interfaceIp}
                  label={parseConnectionMethodName(output?.type)}
                  title={output?.outputName ? output?.outputName : parseConnectionMethodName(output?.type)}
                  cloudId={output?.cloudId}
                  incomplete={output?.incomplete}
                />
                <StyledModal
                  zIndex={100}
                  centered
                  title={
                    <FormattedMessage id="EngineDirectOutputs.editDirectOutput" defaultMessage="Edit direct output" />
                  }
                  open={outputSectionVisible === output.outputName}
                  onOk={handleOKDirectOutput}
                  onCancel={handleCancelDirectOutput}
                  footer={detailView ? null : undefined}
                  okText={
                    <span>
                      <FormattedMessage id="general.save" defaultMessage="Save" />
                    </span>
                  }
                  okButtonProps={{ icon: <SaveOutlined /> }}
                >
                  <OutputFormItem
                    channelId={channelId}
                    disabled={!!detailView}
                    handleRemove={handleRemoveDirect}
                    index={index}
                    initialValue={output}
                    name={output.outputName}
                    outputType={OUTPUTS_TYPE.DIRECT}
                    prefix={[DIRECT_ENGINE_OUTPUTS_PREFIX]}
                    setDisableCondition={setDisableCondition}
                    form={form}
                  />
                </StyledModal>
              </Col>
            </Row>
          );
        })}
      {!detailView && (
        <StyledAddButton type="dashed" icon={<PlusOutlined />} onClick={handleAddNewDirectOutput}>
          <span>
            <FormattedMessage id="EngineDirectOutputs.addNewDirectOutput" defaultMessage="Add Direct Output" />
          </span>
        </StyledAddButton>
      )}
      {outputSectionVisible === NEW && (
        <StyledModal
          zIndex={100}
          centered
          title={<FormattedMessage id="EngineDirectOutputs.newDirectOutputs" defaultMessage="New direct output" />}
          open={outputSectionVisible === NEW}
          onOk={handleOKDirectOutput}
          onCancel={handleCancelDirectOutput}
          okText={
            <span>
              <FormattedMessage id="general.save" defaultMessage="Save" />
            </span>
          }
          okButtonProps={{ icon: <SaveOutlined /> }}
        >
          <OutputFormItem
            channelId={channelId}
            handleRemove={handleRemoveDirect}
            index={numberOfOutputs}
            outputType={OUTPUTS_TYPE.DIRECT}
            prefix={[DIRECT_ENGINE_OUTPUTS_PREFIX]}
            setDisableCondition={setDisableCondition}
            form={form}
          />
        </StyledModal>
      )}
    </>
  );
}

const StyledModal = styled(Modal)`
  min-width: 75%;
`;

const StyledAddButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
`;

EngineDirectOutputs.propTypes = {
  channelId: PropTypes.string,
  detailView: PropTypes.bool,
  handleFinish: PropTypes.func.isRequired,
  outputs: PropTypes.arrayOf(PropTypes.shape(OUTPUT_TYPE)),
};

EngineDirectOutputs.defaultProps = {
  channelId: null,
  detailView: null,
  outputs: null,
};

export default EngineDirectOutputs;
