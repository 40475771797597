import React from "react";
import PropTypes from "prop-types";
import { Select, Form } from "antd";
import { useSelector } from "react-redux";

import { selectors as NODE_SELECTORS } from "../../../ducks/node";

import ConnectionMethodLabel from "../../NodeChannelFormV5/Labels/ConnectionMethodLabel";

import {
  CONNECTION_METHOD_OUTPUT,
  QUICKSTREAM_METHOD_V2,
  DIRECT_ENGINE_OUTPUTS_PREFIX,
} from "../../../lib/utils/constants";
import defaultDirectOutput from "../../../lib/utils/defaultValues/defaultDirectOutput";
import { required } from "../../../lib/utils/formValidation";

const { Item } = Form;
const { Option } = Select;

function OutputConnectionMethodV5({
  connectionMethod,
  disabled,
  getFieldValue,
  isTranscodingOutput,
  isDirectOutput,
  name,
  prefix,
  setConnectionMethod,
  setFieldsValue,
}) {
  const stundAddress = useSelector(NODE_SELECTORS.getStundAddress);

  const fieldListName = name.length <= 2 ? name[0] : name[1];

  const CONNECTION_METHOD_OUTPUT_ARRAY = Object.values({
    ...CONNECTION_METHOD_OUTPUT,
    outQSDirect: QUICKSTREAM_METHOD_V2.outQSDirect,
  });

  const handleChangeConnectionMethod = (value) => {
    if (isDirectOutput) {
      // Clear output - after change connectionMethod
      const defaultOutputValues = {
        ...defaultDirectOutput(value),
        type: value,
        cloudIp: stundAddress,
        autoIp: !!stundAddress,
      };

      const outputs = getFieldValue([DIRECT_ENGINE_OUTPUTS_PREFIX]);
      outputs[fieldListName] = {
        ...defaultOutputValues,
        outputName: outputs[fieldListName].outputName,
      };

      setFieldsValue({
        outputs,
      });
      // End - Clear output
      setConnectionMethod({ ...connectionMethod, [fieldListName]: value });

      return null;
    }

    if (isTranscodingOutput) {
      // Clear output - after change connectionMethod
      const defaultOutputValues = {
        ...defaultDirectOutput(value),
        cloudIp: stundAddress,
        autoIp: !!stundAddress,
      };

      const outputs = getFieldValue([DIRECT_ENGINE_OUTPUTS_PREFIX]);

      outputs[prefix[1]].urlDestinations[prefix[3]] = {
        ...defaultOutputValues,
        outputName: outputs[prefix[1]].urlDestinations[prefix[3]].outputName,
      };
      setFieldsValue({
        outputs,
      });
      // // End - Clear output
      setConnectionMethod({ outputs });

      return null;
    }

    setConnectionMethod(value);

    return null;
  };

  const isOutputSection = isDirectOutput || isTranscodingOutput;

  return (
    <Item name={name} label={<ConnectionMethodLabel />} rules={[required]}>
      <Select disabled={disabled} onChange={handleChangeConnectionMethod}>
        {isOutputSection &&
          CONNECTION_METHOD_OUTPUT_ARRAY.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
      </Select>
    </Item>
  );
}

OutputConnectionMethodV5.propTypes = {
  connectionMethod: PropTypes.string,
  disabled: PropTypes.bool,
  getFieldValue: PropTypes.func,
  isDirectOutput: PropTypes.bool,
  isTranscodingOutput: PropTypes.bool,
  name: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  setConnectionMethod: PropTypes.func,
  setFieldsValue: PropTypes.func,
};

OutputConnectionMethodV5.defaultProps = {
  connectionMethod: null,
  disabled: false,
  getFieldValue: null,
  isTranscodingOutput: false,
  isDirectOutput: false,
  prefix: null,
  setFieldsValue: null,
  setConnectionMethod: null,
};

export default OutputConnectionMethodV5;
