import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import useNodeVersion from "../../../hooks/useNodeVersion";

import StatisticsLive from "./StatisticsLive";
// import StatisticsLiveNodeV5 from "./StatisticsLiveNodeV5";
// import StatisticHistorical from "./StatisticHistorical";
import StatisticHistoricalV5 from "./StatisticHistoricalV5";

const StatisticsContainer = ({ channelId, setChannelId, channelDetailView }) => {
  const { isV5Node } = useNodeVersion();
  const { cwid } = useParams();

  return (
    <>
      {!isV5Node && (
        <StatisticsLive
          channelId={channelId}
          setChannelId={setChannelId}
          cwid={cwid}
          channelDetailView={channelDetailView}
        />
      )}
      {isV5Node && (
        <StatisticHistoricalV5
          channelId={channelId}
          channelDetailView={channelDetailView}
          setChannelId={setChannelId}
        />
      )}
    </>
  );
};

StatisticsContainer.propTypes = {
  channelDetailView: PropTypes.bool,
  channelId: PropTypes.string,
  setChannelId: PropTypes.func,
};

StatisticsContainer.defaultProps = {
  channelDetailView: null,
  channelId: "",
  setChannelId: null,
};

export default StatisticsContainer;
