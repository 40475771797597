import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Switch } from "antd";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import React, { useEffect, useState, useCallback } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";

import { PlatformAPI } from "../../../lib/api";
import globalTranslations from "../../../lib/translations";
import { VIRTUAL_CHANNEL_PERMISSION } from "../../../lib/utils/constants";
import { az09space, max32characters, required } from "../../../lib/utils/formValidation";
import { errorNotification } from "../../../lib/utils/notification";

import PassphraseField from "../../../components/Fields/PassphraseField";

const { Item, List } = Form;

const translations = defineMessages({
  description: {
    id: "PermissionDynamicForm.permissionName",
    defaultMessage: "Share name",
  },
  ChannelDetailViewUserName: {
    id: "ChannelDetailView.username",
    defaultMessage: "Username",
  },
  usernameValidation: {
    id: "ChannelDetailView.usernameValidation",
    defaultMessage: "Please input valid username",
  },
});

const PermissionDynamicForm = ({
  getFieldValue,
  setFieldsValue,
  channelName,
  loading,
  setLoading,
  validateForm,
  form,
}) => {
  const { formatMessage } = useIntl();
  const [isValidName, setIsValidName] = useState(false);

  useEffect(() => {
    const validate = async () => {
      await validateForm();
    };
    validate();
  }, [isValidName]);

  const setInitialFieldValue = () => {
    setLoading(true);
    const fieldValue = getFieldValue("users");
    const initialUsersData = fieldValue.map((value) => {
      if (value?.description) {
        setLoading(false);

        return value;
      }
      setLoading(false);

      return { ...value, description: channelName, encryptionEnabled: false, senderPays: false };
    });

    setFieldsValue({ users: initialUsersData });
  };

  const handleSearch = useCallback(
    debounce(
      async (event) => {
        const inputUsernameValue = event?.target?.value;

        if (!inputUsernameValue) return;
        if (inputUsernameValue === "") return;

        try {
          setLoading(true);
          const result = await PlatformAPI.checkUser(
            { username: inputUsernameValue },
            { errorNotification: errorNotification(formatMessage) }
          );

          setIsValidName(result);
        } finally {
          setLoading(false);
        }
      },
      500,
      { trailing: true, leading: false }
    ),
    []
  );

  const userAPIValidator = async (rule) => {
    if (!rule) return Promise.reject(formatMessage(translations.usernameValidation));

    if (!isValidName) {
      return Promise.reject(formatMessage(translations.usernameValidation));
    }

    return Promise.resolve();
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };

  return (
    <List name="users">
      {(fields, { add }) => {
        return (
          <>
            {fields.map((field, index) => (
              <Item
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                required={false}
                key={field.key}
                fieldKey={field.fieldKey}
              >
                <Item
                  label={<FormattedMessage id="PermissionDynamicForm.user" defaultMessage="User" />}
                  name={[field.name, "username"]}
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    required,
                    {
                      validator: userAPIValidator,
                    },
                  ]}
                >
                  <Input placeholder={formatMessage(translations.ChannelDetailViewUserName)} onChange={handleSearch} />
                </Item>
                <HiddenItem name={[field.name, "senderPays"]} valuePropName="checked" label="senderPays">
                  <Switch
                    defaultChecked={false}
                    checkedChildren={formatMessage(globalTranslations.enabled)}
                    unCheckedChildren={formatMessage(globalTranslations.disabled)}
                  />
                </HiddenItem>
                <Item
                  label={<FormattedMessage id="PermissionDynamicForm.shareName" defaultMessage="Share name" />}
                  name={[field.name, "description"]}
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[required, az09space, max32characters]}
                >
                  <Input placeholder={formatMessage(translations.description)} />
                </Item>

                <PassphraseField
                  prefix={[field.name]}
                  setFieldsValue={setFieldsValue}
                  getFieldValue={getFieldValue}
                  type={VIRTUAL_CHANNEL_PERMISSION}
                  form={form}
                />
              </Item>
            ))}
            {fields.length === 0 && (
              <Item>
                <StyledButton>
                  <Button
                    type="dashed"
                    style={{ width: "50%" }}
                    disabled={loading}
                    onClick={() => {
                      add();
                      setInitialFieldValue();
                    }}
                  >
                    <PlusOutlined /> <FormattedMessage id="PermissionDynamicForm.addUser" defaultMessage="Add user" />
                  </Button>
                </StyledButton>
              </Item>
            )}
          </>
        );
      }}
    </List>
  );
};

const StyledButton = styled.div`
  display: flex;
  justify-content: center;
`;

const HiddenItem = styled(Item)`
  display: none;
  visibility: hidden;
`;

PermissionDynamicForm.propTypes = {
  channelName: PropTypes.string,
  getFieldValue: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

PermissionDynamicForm.defaultProps = {
  channelName: null,
};

export default PermissionDynamicForm;
