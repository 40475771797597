import React from "react";
import { Form, InputNumber } from "antd";
import PropTypes from "prop-types";

const { Item } = Form;

function AudioStreamCount({ disabled, prefix }) {
  return (
    <Item name={prefix} disabled={disabled} hidden>
      <InputNumber type="number" min={1} max={1} />
    </Item>
  );
}

AudioStreamCount.propTypes = {
  disabled: PropTypes.bool.isRequired,
  prefix: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AudioStreamCount;
