import React, { useEffect, useState, useCallback } from "react";
import { LineChartOutlined, CodeOutlined, ControlOutlined, BranchesOutlined } from "@ant-design/icons";
import { Tabs, Card, Spin, Row, Col, Skeleton } from "antd";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useParams, useSearchParams } from "react-router-dom";

import store from "../../../store";
import { actions as NAVIGATION_ACTIONS } from "../../../ducks/navigation";
import { selectors as NODE_SELECTORS, actions as NODE_ACTIONS } from "../../../ducks/node";
import { actions as LOADING_ACTIONS } from "../../../ducks/loadingData";
import { selectors as MQTT_SELECTORS } from "../../../ducks/mqtt";

import NodeV4ChannelsList from "../NodeV4ChannelsList";
import NodeV5ChannelsList from "../NodeV5ChannelsList";
import NodePorts from "../NodePorts";
import Logs from "../../../components/Logs";
import LogsV5 from "../../../components/LogsV5";
import NodeStatisticsContainer from "../NodeStatistics";
import GlobalErrorBoundaries from "../../../components/ErrorBoundaries/GlobalErrorBoundaries";
import SearchInputChannelsList from "../../../components/SearchInputChannelsList";

import MQTTService from "../../../services/mqtt";
import useNodeStunAddress from "../../../hooks/useNodeStunAddress";
import useNodeChannels from "../../../hooks/useNodeChannels";
import useNodeCapabilities from "../../../hooks/useNodeCapabilities";
import useNodeVersion from "../../../hooks/useNodeVersion";

import { MQTT_STATUS, NODE_CHANEL_TABS, MESSAGE_TYPE, UNDEFINED } from "../../../lib/utils/constants";

const NodeDashboard = ({ hostName, MQTTConnection, MQTTStatus, nodes }) => {
  const [statisticChannelId, setStatisticChannelId] = useState();
  const { cwid } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const queryChannelId = searchParams.get("c");
  const queryTab = searchParams.get("tab") || NODE_CHANEL_TABS.channelList;

  const [channelId, setChannelId] = useState(queryChannelId ? queryChannelId.toString() : null);

  useNodeStunAddress();
  useNodeCapabilities();
  const nodeChannels = useNodeChannels();
  const { isV5Node, isV4Node, isMinV5 } = useNodeVersion();

  useEffect(() => {
    const [...channelIds] = nodeChannels && nodeChannels.keys();
    const isChannelIdOnNodeChannels = nodeChannels.has(statisticChannelId);

    if (!statisticChannelId || !isChannelIdOnNodeChannels) {
      setStatisticChannelId(channelIds[0]);
    }
  }, [nodeChannels, statisticChannelId]);

  const nodeData = nodes.find((node) => node.cwid === cwid);
  const isConnection = MQTTConnection && MQTTStatus === MQTT_STATUS.CONNECTED;

  const getHostname = useCallback(() => {
    if (!hostName) {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.GET_HOST_NAME,
        },
      });

      store.dispatch(LOADING_ACTIONS.SET_LOADING(MESSAGE_TYPE.GET_HOST_NAME));
    }
  }, [cwid, hostName]);

  useEffect(() => {
    if (!isMinV5) {
      return null;
    }

    MQTTService.sendMessage({
      topic: `node/${cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.REQUEST_LIVE_STATS,
      },
    });

    const liveDataInterval = setInterval(() => {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.REQUEST_LIVE_STATS,
        },
      });
    }, 290000);

    return () => {
      if (isMinV5) {
        clearInterval(liveDataInterval);
      }
    };
  }, [cwid]);

  useEffect(() => {
    if (isConnection) {
      getHostname();
    }
  }, [MQTTConnection, MQTTStatus, getHostname, isConnection]);

  useEffect(() => {
    if (nodeData?.cwid) {
      store.dispatch(NODE_ACTIONS.SET_NODE_CONNECTION(nodeData));
    }
  }, [nodeData]);

  const openTabs = (key) => {
    if (key === NODE_CHANEL_TABS.search) {
      return;
    }

    store.dispatch(NAVIGATION_ACTIONS.SET_CHANNELS_ACTIVE_TAB({ channelsActiveTab: key }));
    searchParams.set("tab", key);
    setSearchParams(searchParams);
    window.localStorage.setItem(`${cwid}-paginationNodeChannelList`, `?tab=${key}`);
  };

  if (!isConnection) {
    return (
      <GlobalErrorBoundaries>
        <Row justify="center">
          <Col span={24}>
            <Spin
              tip={
                <FormattedMessage id="NodeDashboard.waitingForCloud" defaultMessage="Waiting for cloud connection" />
              }
            >
              <Skeleton active />
            </Spin>
          </Col>
        </Row>
      </GlobalErrorBoundaries>
    );
  }

  const isNodeChannelsList =
    queryTab === NODE_CHANEL_TABS.channelList || queryTab === undefined || queryTab === UNDEFINED;

  const items = [
    {
      icon: <ControlOutlined />,
      label: (
        <span>
          <FormattedMessage id="NodeDashboard.channelList" defaultMessage="Channel list" />
        </span>
      ),
      key: NODE_CHANEL_TABS.channelList,
      ...(isV5Node && { children: <NodeV5ChannelsList /> }),
      ...(isV4Node && {
        children: <NodeV4ChannelsList />,
      }),
    },
    {
      icon: <CodeOutlined />,
      label: (
        <span>
          <FormattedMessage id="NodeDashboard.logs" defaultMessage="Logs" />
        </span>
      ),
      key: NODE_CHANEL_TABS.logs,
      children: (
        <>
          {isV5Node && <LogsV5 channelId={channelId} setChannelId={setChannelId} />}
          {isV4Node && <Logs channelId={channelId} setChannelId={setChannelId} />}
        </>
      ),
    },
    {
      icon: <LineChartOutlined />,
      label: (
        <span>
          <FormattedMessage id="NodeDashboard.statistics" defaultMessage="Statistics" />
        </span>
      ),
      key: NODE_CHANEL_TABS.statistics,
      children: <NodeStatisticsContainer channelId={statisticChannelId} setChannelId={setStatisticChannelId} />,
    },
    {
      icon: <BranchesOutlined />,
      label: (
        <span>
          <FormattedMessage id="NodeDashboard.ports" defaultMessage="Ports" />
        </span>
      ),
      key: NODE_CHANEL_TABS.ports,
      children: <NodePorts activeLink />,
    },
    ...(isNodeChannelsList
      ? [
          {
            label: <SearchInputChannelsList />,
            key: NODE_CHANEL_TABS.search,
          },
        ]
      : []),
  ];

  return (
    <GlobalErrorBoundaries>
      <Card>
        <Tabs activeKey={queryTab} onTabClick={openTabs} items={items} size="small" destroyInactiveTabPane />
      </Card>
    </GlobalErrorBoundaries>
  );
};

NodeDashboard.propTypes = {
  MQTTStatus: PropTypes.string,
  hostName: PropTypes.string,
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      cnn: PropTypes.string.isRequired,
      cwid: PropTypes.string.isRequired,
    })
  ).isRequired,
  MQTTConnection: PropTypes.bool,
};

NodeDashboard.defaultProps = {
  MQTTStatus: null,
  hostName: null,
  MQTTConnection: null,
};

const mapStateToProps = (state) => ({
  hostName: NODE_SELECTORS.getCnn(state),
  MQTTConnection: MQTT_SELECTORS.getMqttConnection(state),
  MQTTStatus: MQTT_SELECTORS.getStatus(state),
  nodes: MQTT_SELECTORS.getNodes(state),
});

export default connect(mapStateToProps, null)(NodeDashboard);
