import { CONNECTION_METHOD_OUTPUT, DEFAULT_VALUES, SELECT_DEFAULT_AUTO, QUICKSTREAM_METHOD_V2 } from "../constants";

export const OUT_UDP_DEFAULT = {
  type: CONNECTION_METHOD_OUTPUT.outUdp.value,
  interfaceIp: SELECT_DEFAULT_AUTO.value,
  localPort: DEFAULT_VALUES.LOCALPORT,
  destinationAddress: DEFAULT_VALUES.IP,
  destinationPort: DEFAULT_VALUES.PORT,
  udpTTL: DEFAULT_VALUES.TTL,
};

const OUT_SRT_LISTENER_DEFAULT = {
  type: CONNECTION_METHOD_OUTPUT.outSrtListener.value,
  interfaceIp: SELECT_DEFAULT_AUTO.value,
  // localPort: DEFAULT_VALUES.PORT,
  srtLatency: DEFAULT_VALUES.SRT_LATENCY,
  srtOverhead: DEFAULT_VALUES.OVERHEAD,
  setIPTOS: false,
  setIPTTL: false,
  setStreamId: false,
  encryptionEnabled: false,
};
const OUT_SRT_CALLER = {
  ...OUT_SRT_LISTENER_DEFAULT,
  localPort: DEFAULT_VALUES.LOCALPORT,
  type: CONNECTION_METHOD_OUTPUT.outSrtCaller.value,
  destinationAddress: DEFAULT_VALUES.IP,
  destinationPort: DEFAULT_VALUES.PORT,
};

export const DEFAULT_OUT_QUICKSTREAM_METHOD_V2 = {
  type: QUICKSTREAM_METHOD_V2.outQSDirect.value,
  srtLatency: DEFAULT_VALUES.SRT_LATENCY,
  srtOverhead: DEFAULT_VALUES.OVERHEAD,
  interfaceIp: SELECT_DEFAULT_AUTO.value,
  encryptionEnabled: false,
  setIPTOS: false,
  setIPTTL: false,
  // localPort: DEFAULT_VALUES.PORT,
};

const defaultDirectOutput = (type) => {
  switch (type) {
    case CONNECTION_METHOD_OUTPUT.outUdp.value:
      return OUT_UDP_DEFAULT;
    case CONNECTION_METHOD_OUTPUT.outSrtListener.value:
      return OUT_SRT_LISTENER_DEFAULT;
    case CONNECTION_METHOD_OUTPUT.outSrtCaller.value:
      return OUT_SRT_CALLER;
    case QUICKSTREAM_METHOD_V2.outQSDirect.value:
      return DEFAULT_OUT_QUICKSTREAM_METHOD_V2;
    default:
      return {};
  }
};

export default defaultDirectOutput;
