import React, { useEffect } from "react";
import { Card, Tabs } from "antd";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { LineChartOutlined, BranchesOutlined, ControlOutlined, CloudOutlined } from "@ant-design/icons";

import { selectors as MQTT_CONNECTION_SELECTORS } from "../../../ducks/mqtt";
import { actions as NODE_ACTIONS } from "../../../ducks/node";

import { errorNotification } from "../../../lib/utils/notification";
import store from "../../../store";

import useNodeVersion from "../../../hooks/useNodeVersion";

import NodeLiveStatistics from "./NodeLiveStatistics";
import NodeInterfaces from "./NodeInterfaces";
import GlobalErrorBoundaries from "../../../components/ErrorBoundaries/GlobalErrorBoundaries";
import NodeCloudLicenseUsage from "./NodeCloudLicenseUsage";
import GeneralTab from "./GeneralTab";

const translations = defineMessages({
  wrongCloudNodeName: {
    id: "general.wrongCloudNodeName",
    defaultMessage: "Node does not exist in Quickstream Cloud",
  },
});

const NodeSystem = ({ nodes }) => {
  const { cwid } = useParams();
  const { isV5Node } = useNodeVersion();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const nodeData = nodes.find((node) => node.cwid === cwid);
  const [searchParams, setSearchParams] = useSearchParams();
  const queryTab = searchParams.get("tab") || "general";

  useEffect(() => {
    if (nodeData?.cwid) {
      store.dispatch(NODE_ACTIONS.SET_NODE_CONNECTION(nodeData));
    }
  }, [nodeData]);

  if (nodes.length > 0 && !nodeData) {
    errorNotification(formatMessage)(translations.wrongCloudNodeName);
    navigate("/");
  }

  const items = [
    {
      icon: <ControlOutlined />,
      label: (
        <span>
          <FormattedMessage id="ChannelSystem.General" defaultMessage="General" />
        </span>
      ),
      key: "general",
      children: <GeneralTab />,
    },
    {
      icon: <LineChartOutlined />,
      label: (
        <span>
          <FormattedMessage id="ChannelSystem.statistics" defaultMessage="Statistics" />
        </span>
      ),
      key: "statistics",
      children: <NodeLiveStatistics />,
    },
    {
      icon: <BranchesOutlined />,
      label: (
        <span>
          <FormattedMessage id="ChannelSystem.network" defaultMessage="Network" />
        </span>
      ),
      key: "network",
      children: <NodeInterfaces />,
    },
    ...(isV5Node
      ? [
          {
            icon: <CloudOutlined />,
            label: (
              <span>
                <FormattedMessage id="ChannelSystem.nodeLicensesUsage" defaultMessage="Node licenses usage" />
              </span>
            ),
            key: "cloudLicenseUsage",
            children: <NodeCloudLicenseUsage />,
          },
        ]
      : []),
  ];

  const handleChangeTabs = (selectedTab) => {
    searchParams.set("tab", selectedTab);
    setSearchParams(searchParams);
  };

  return (
    <GlobalErrorBoundaries>
      <Card>
        <Tabs activeKey={queryTab} items={items} onChange={handleChangeTabs} />
      </Card>
    </GlobalErrorBoundaries>
  );
};

NodeSystem.propTypes = {
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      cnn: PropTypes.string.isRequired,
      cwid: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const mapStateToProps = (state) => ({
  nodes: MQTT_CONNECTION_SELECTORS.getNodes(state),
});

export default connect(mapStateToProps, null)(NodeSystem);
