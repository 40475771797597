import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Badge, Divider, Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";

import { selectors as NODE_DEVICES_SELECTORS } from "../../../../ducks/nodeDevices";

import themeColor from "../../../../lib/style/theme";
import { OUTPUTS_TYPES, GLOBAL_VIDEO_RESIZE } from "../../../../lib/utils/constants";
import formatLabels from "../../../../lib/utils/formatLabels";

import IncompleteConfig from "../../../IncompleteConfig";
import DestinationsTooltip from "./DestinationsTooltip";

const { Ribbon } = Badge;

function EngineTranscodingOutputElement({ handleClick, label, muxedOutput, incomplete, type }) {
  const numberOfDestinations = muxedOutput && muxedOutput.urlDestinations ? muxedOutput?.urlDestinations.length : 0;
  const noIncomplete = !incomplete || (incomplete && incomplete.length === 0);
  const isOutputNdiType = type === OUTPUTS_TYPES.outputNdi.value;
  const isOutputUrlType = type === OUTPUTS_TYPES.outputUrl.value;
  const isOutputRtmpType = type === OUTPUTS_TYPES.outputRtmp.value;
  const isOutputSDIType = type === OUTPUTS_TYPES.outputDecklink.value;
  const decklinkOutputs = useSelector(NODE_DEVICES_SELECTORS.getDecklinkOutputs);
  const parsedDecklinkOutputDevice = (decklinkOutputs &&
    decklinkOutputs.find((output) => output.device === muxedOutput?.device)) || {
    desc: muxedOutput?.device,
  };

  const parsedOutputFormat =
    muxedOutput?.format && muxedOutput?.format in formatLabels ? formatLabels[muxedOutput?.format] : muxedOutput.format;
  const parsedVideoResize =
    GLOBAL_VIDEO_RESIZE.find((resize) => resize.value === muxedOutput?.resize) || muxedOutput?.resize;

  return (
    <Ribbon
      text={<IncompleteConfig incomplete={incomplete} color="#FFFFFF" />}
      color={noIncomplete ? themeColor.green : themeColor.orange}
    >
      <StyledContainer onClick={handleClick}>
        <StyledAsideType>{label}</StyledAsideType>
        <StyledBoldText>{muxedOutput?.name}</StyledBoldText>
        {isOutputSDIType && (
          <>
            <div>
              <StyledText>
                <FormattedMessage id="EngineTranscodingOutputElement.name" defaultMessage="Name" />:
              </StyledText>
              <StyledBoldText>{muxedOutput?.name}</StyledBoldText>
            </div>
            <div>
              <StyledText>
                <FormattedMessage id="EngineTranscodingOutputElement.device" defaultMessage="Device" />:
              </StyledText>
              <StyledBoldText>{parsedDecklinkOutputDevice?.desc}</StyledBoldText>
            </div>
            <div>
              <StyledText>
                <FormattedMessage id="EngineTranscodingOutputElement.format" defaultMessage="Format" />:
              </StyledText>
              <StyledBoldText>{parsedOutputFormat}</StyledBoldText>
              <Divider type="vertical" />
              <StyledText>
                <FormattedMessage id="EngineTranscodingOutputElement.resize" defaultMessage="Resize" />:
              </StyledText>
              <StyledBoldText>{parsedVideoResize ? parsedVideoResize.label : muxedOutput?.videoResize}</StyledBoldText>
              <Divider type="vertical" />
              <StyledText>
                <FormattedMessage id="EngineTranscodingOutputElement.audioChannels" defaultMessage="Audio channels" />:
              </StyledText>
              <StyledBoldText>{muxedOutput?.audioChannels}</StyledBoldText>
            </div>
          </>
        )}
        {isOutputRtmpType && (
          <>
            <div>
              <StyledText>
                <FormattedMessage id="EngineTranscodingOutputElement.url" defaultMessage="Url" />:
              </StyledText>
              <StyledBoldText>{muxedOutput?.url}</StyledBoldText>
            </div>

            {muxedOutput?.key && (
              <div>
                <StyledText>
                  <FormattedMessage
                    id="EngineTranscodingOutputElement.streamNameOrKey"
                    defaultMessage="Stream name or Key"
                  />
                  :
                </StyledText>
                <StyledBoldText>{muxedOutput?.key}</StyledBoldText>
              </div>
            )}
          </>
        )}
        {isOutputNdiType && (
          <div>
            <StyledText>
              <FormattedMessage id="EngineTranscodingOutputElement.stream" defaultMessage="Stream" />:
            </StyledText>
            <StyledBoldText>{muxedOutput?.streamName}</StyledBoldText>
          </div>
        )}
        {isOutputUrlType && (
          <Tooltip
            placement="top"
            overlayStyle={{ minWidth: 800 }}
            title={<DestinationsTooltip destinations={muxedOutput.urlDestinations} />}
          >
            <div>
              <StyledText>
                <FormattedMessage id="EngineTranscodingOutputElement.destinations" defaultMessage="Destinations" />{" "}
                <QuestionCircleFilled />:
              </StyledText>
              <StyledBoldText>
                <Badge count={numberOfDestinations} showZero style={{ backgroundColor: themeColor.orange }} />
              </StyledBoldText>
            </div>
          </Tooltip>
        )}
      </StyledContainer>
    </Ribbon>
  );
}

const StyledText = styled.span`
  margin-right: 5px;
`;

const StyledBoldText = styled.span`
  font-weight: bold;
`;

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 105px;
  margin: 0 0 15px 30px;
  padding: 10px;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    box-shadow:
      0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12),
      0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
`;

const StyledAsideType = styled.span`
  font: bold 11px Sans-Serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: ${themeColor.orange};
  color: #fff;
  padding: 5px;
  line-height: 24px;
  position: absolute;
  top: 104px;
  left: -1px;
  transform-origin: 0 0;
  transform: rotate(270deg);
  width: 105px;
  text-align: center;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
`;

EngineTranscodingOutputElement.propTypes = {
  handleClick: PropTypes.func.isRequired,
  label: PropTypes.element.isRequired,
  muxedOutput: PropTypes.object,
  type: PropTypes.string,
  incomplete: PropTypes.arrayOf(PropTypes.number),
};

EngineTranscodingOutputElement.defaultProps = {
  muxedOutput: null,
  incomplete: null,
  type: null,
};

export default EngineTranscodingOutputElement;
