import React, { useState, useContext } from "react";
import { Form, Select, Divider, Card, Spin, Skeleton } from "antd";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";

import { required } from "../../../../lib/utils/formValidation";
import transcodingInputURLDefaultValues from "../../../../lib/utils/defaultValues/transcodingInputURLDefaultValues";
import { MAIN_CONNECTION_TYPE } from "../../../../lib/utils/types/inputURLTypes";
import { CAPABILITIES_TYPE } from "../../../../lib/utils/types/capabilitiesTypes";

import { selectors as NODE_DEVICES_SELECTORS } from "../../../../ducks/nodeDevices";

import {
  INPUT_DECKLINK_TYPE,
  INPUT_URL_TYPE,
  INPUT_WEBCAM_TYPE,
  SELECT_DEFAULT_AUTO,
  INPUT_TYPES,
  NODE_DEVICES_TYPE,
} from "../../../../lib/utils/constants";
import InputTranscodingStreamURLForm from "./InputTranscodingStreamURLForm";
import UpdateNodeDevices from "../../../UpdateNodeDevices";

import InputSDI from "../InputsStream/InputSDI";
import InputWebcam from "../InputsStream/InputWebcam";
import InputRTMP from "../InputsStream/InputRTMP";
import InputRTP from "../InputsStream/InputRTP";
import InputNDI from "../InputsStream/InputNDI";

import { selectors as NODE_SELECTORS } from "../../../../ducks/node";
import { FormV5Context } from "../../NodeChannelFormV5";

const { Item } = Form;
const { Option } = Select;

function InputTranscodingSectionContainer({
  capabilities,
  decklinkInputs,
  disabled,
  initialValue,
  nodeDevicesLoaded,
  setSelectedInputType,
  sharedChannelList,
  stundAddress,
  webcamAudioInputs,
  webcamVideoInputs,
}) {
  const form = useContext(FormV5Context);
  const { getFieldValue, setFieldsValue, validateFields } = form;

  const [inputType, setInputType] = useState(getFieldValue(["mainInput", "type"]));

  const handleChangeInputType = (value) => {
    setSelectedInputType(value);
    const formData = getFieldValue();
    delete formData.mainInput;

    if (value === INPUT_DECKLINK_TYPE.value) {
      setFieldsValue({
        mainInput: {
          type: value,
          format: SELECT_DEFAULT_AUTO.value,
          audioChannels: 2,
          audioSource: SELECT_DEFAULT_AUTO.value,
          videoSource: SELECT_DEFAULT_AUTO.value,
        },
      });
    }

    if (value === INPUT_URL_TYPE.value) {
      const initialInputValues = transcodingInputURLDefaultValues({
        input: initialValue,
      });
      setFieldsValue({ mainInput: initialInputValues });
    }

    if (value === INPUT_WEBCAM_TYPE.value) {
      setFieldsValue({ mainInput: { type: value } });
    }

    setFieldsValue({ mainInput: { type: value } });
    setInputType(value);
  };

  const isInputURLTypeSelected = inputType === INPUT_URL_TYPE.value;
  const isInputSDITypeSelected = inputType === INPUT_DECKLINK_TYPE.value;
  const isInputWEBCAMTypeSelected = inputType === INPUT_WEBCAM_TYPE.value;
  const isInputRTMPTypeSelected = inputType === INPUT_TYPES.inputRtmp.value;
  const isInputRTPTypeSelected = inputType === INPUT_TYPES.inputMpegtsOverRtp.value;
  const isInputNDITypeSelected = inputType === INPUT_TYPES.inputNdi.value;

  const isDecklinkInputs = decklinkInputs.length > 0;
  const isWebcamAudioInputs = webcamAudioInputs.length > 0;
  const isWebcamVideoInputs = webcamVideoInputs.length > 0;

  const INPUT_TYPES_ARRAY = Object.values(INPUT_TYPES);
  const INPUT_STREAM_DEVICE_TYPE = INPUT_TYPES_ARRAY;

  const checkIfOptionDisabled = (optionValue) => {
    if (capabilities?.inputTypes && !capabilities?.inputTypes.includes(optionValue)) {
      return true;
    }
    if (optionValue === INPUT_DECKLINK_TYPE.value && !isDecklinkInputs) {
      return true;
    }

    if (optionValue === INPUT_WEBCAM_TYPE.value && (!isWebcamAudioInputs || !isWebcamVideoInputs)) {
      return true;
    }

    return false;
  };

  return (
    <Card extra={<UpdateNodeDevices />}>
      <Item
        name={["mainInput", "type"]}
        label={<FormattedMessage id="InputTranscodingSectionContainer.type" defaultMessage="Type" />}
        rules={[required]}
      >
        <Select disabled={disabled} onChange={handleChangeInputType}>
          {INPUT_STREAM_DEVICE_TYPE.map((option) => (
            <Option key={option.value} value={option.value} disabled={checkIfOptionDisabled(option.value)}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Item>
      {nodeDevicesLoaded && (
        <>
          <StyledSpin
            tip={
              <FormattedMessage
                id="InputTranscodingSectionContainer.loadingNodeDevices"
                defaultMessage="Loading node devices list..."
              />
            }
          >
            <Skeleton active />
          </StyledSpin>
          <Divider />
        </>
      )}
      {isInputURLTypeSelected && (
        <InputTranscodingStreamURLForm
          channelList={sharedChannelList}
          disabled={disabled}
          initialValue={initialValue}
          sharedChannelList={sharedChannelList}
          stundAddress={stundAddress}
        />
      )}
      {isInputRTMPTypeSelected && <InputRTMP prefix={["mainInput"]} disabled={disabled} />}
      {isInputRTPTypeSelected && <InputRTP disabled={disabled} prefix={["mainInput"]} />}
      {isInputNDITypeSelected && (
        <InputNDI
          disabled={disabled}
          initialValue={{ streamSource: initialValue?.streamSource, streamAddr: initialValue?.streamAddr }}
          prefix={["mainInput"]}
        />
      )}
      {isInputWEBCAMTypeSelected && (
        <InputWebcam
          audioInputs={webcamAudioInputs}
          disabled={disabled}
          getFieldValue={getFieldValue}
          setFieldsValue={setFieldsValue}
          videoInputs={webcamVideoInputs}
          validateFields={validateFields}
          prefix={["mainInput"]}
        />
      )}
      {isInputSDITypeSelected && (
        <InputSDI
          getFieldValue={getFieldValue}
          inputs={decklinkInputs}
          disabled={disabled}
          type={NODE_DEVICES_TYPE.decklinkInputs}
          setFieldsValue={setFieldsValue}
          prefix={["mainInput"]}
        />
      )}
    </Card>
  );
}

const StyledSpin = styled(Spin)`
  width: 100%;
`;

InputTranscodingSectionContainer.propTypes = {
  capabilities: PropTypes.shape(CAPABILITIES_TYPE),
  disabled: PropTypes.bool,
  decklinkInputs: PropTypes.arrayOf(
    PropTypes.shape({
      desc: PropTypes.string,
      device: PropTypes.string,
    })
  ).isRequired,
  initialValue: PropTypes.shape({
    type: PropTypes.string.isRequired,
    switchInputBackup: PropTypes.bool,
    ...MAIN_CONNECTION_TYPE,
  }),
  stundAddress: PropTypes.string,
  sharedChannelList: PropTypes.array,
  webcamAudioInputs: PropTypes.arrayOf(
    PropTypes.shape({
      device: PropTypes.string,
    })
  ).isRequired,
  webcamVideoInputs: PropTypes.arrayOf(
    PropTypes.shape({
      device: PropTypes.string,
    })
  ).isRequired,
  nodeDevicesLoaded: PropTypes.bool,
  setSelectedInputType: PropTypes.func.isRequired,
};

InputTranscodingSectionContainer.defaultProps = {
  capabilities: null,
  disabled: null,
  initialValue: null,
  nodeDevicesLoaded: null,
  sharedChannelList: null,
  stundAddress: null,
};

const mapStateToProps = (state) => ({
  capabilities: NODE_SELECTORS.getNodeCapabilities(state),
  decklinkInputs: NODE_DEVICES_SELECTORS.getDecklinkInputs(state),
  decklinkOutputs: NODE_DEVICES_SELECTORS.getDecklinkOutputs(state),
  webcamAudioInputs: NODE_DEVICES_SELECTORS.getWebcamAudioInputs(state),
  webcamVideoInputs: NODE_DEVICES_SELECTORS.getWebcamVideoInputs(state),
  nodeDevicesLoaded: NODE_DEVICES_SELECTORS.getLoadingDevices(state),
});

export default connect(mapStateToProps, null)(InputTranscodingSectionContainer);
