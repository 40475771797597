import React, { useState, useContext } from "react";
import { Row, Col, Card, Divider } from "antd";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { CONNECTION_METHOD_INPUT } from "../../../../lib/utils/constants";
import { INPUT_BACKUP_TYPE, INPUT_BACKUP_TRIGGERS_TYPE } from "../../../../lib/utils/types/inputURLTypes";

import InputConnectionMethod from "../../../Fields/InputConnectionMethod";
import InputStreamUdpSection from "../InputStreamSection/InputStreamUdpSection";
import InputStreamSrtSection from "../InputStreamSection/InputStreamSrtSection";
import InputStreamSectionBackupTriggers from "../../InputStreamSectionBackupTriggers";

import { FormV5Context } from "../..";

const SRT_METHODS = [CONNECTION_METHOD_INPUT.inSrtListener.value, CONNECTION_METHOD_INPUT.inSrtCaller.value];
const UDP_METHODS = [CONNECTION_METHOD_INPUT.inUdpUnicast.value, CONNECTION_METHOD_INPUT.inUdpMulticast.value];

function InputStreamSectionBackup({ disabled, initialValue }) {
  const form = useContext(FormV5Context);
  const { getFieldValue, setFieldsValue } = form;

  const backupConnectionMethodValue = getFieldValue(["input", "backupConnection", "type"]);

  const [backupConnectionMethod, setBackupConnectionMethod] = useState(
    initialValue?.backupConnection?.type || backupConnectionMethodValue || CONNECTION_METHOD_INPUT.inUdpUnicast.value
  );

  const isUDPBackupMethod = UDP_METHODS.includes(backupConnectionMethod);
  const isSRTBackupMethod = SRT_METHODS.includes(backupConnectionMethod);

  return (
    <Card title={<FormattedMessage id="InputStreamSectionBackup.backup" defaultMessage="Backup" />}>
      <Row type="flex" justify="start" gutter={24}>
        <Col span={24}>
          <InputConnectionMethod
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
            disabled={disabled}
            name={["input", "backupConnection", "type"]}
            setConnectionMethod={setBackupConnectionMethod}
            isBackup
          />
        </Col>
        <Divider />
        {isUDPBackupMethod && (
          <InputStreamUdpSection
            connectionMethod={backupConnectionMethod}
            disabled={disabled}
            initialValue={initialValue?.backupConnection}
            prefix={["input", "backupConnection"]}
          />
        )}
        {isSRTBackupMethod && (
          <InputStreamSrtSection
            connectionMethod={backupConnectionMethod}
            disabled={disabled}
            getFieldValue={getFieldValue}
            initialValue={initialValue?.backupConnection}
            prefix={["input", "backupConnection"]}
            setFieldsValue={setFieldsValue}
            isBackup
            form={form}
          />
        )}
      </Row>
      <InputStreamSectionBackupTriggers disabled={disabled} prefix={["input", "backupTriggers"]} form={form} />
    </Card>
  );
}

InputStreamSectionBackup.propTypes = {
  disabled: PropTypes.bool,
  initialValue: PropTypes.shape({
    ...INPUT_BACKUP_TYPE,
    ...INPUT_BACKUP_TRIGGERS_TYPE,
  }),
};

InputStreamSectionBackup.defaultProps = {
  disabled: null,
  initialValue: null,
};

export default InputStreamSectionBackup;
