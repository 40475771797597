import React, { useState, useContext } from "react";
import { Card, Row, Button, Modal, Col, Spin, Popconfirm, Form } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { PlusOutlined, SearchOutlined, QuestionCircleOutlined, SaveOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import { selectors as NODE_DEVICES_SELECTORS } from "../../../../ducks/nodeDevices";

import parseEmergencyModeName from "../../../../lib/utils/parseEmergencyModeName";
import parseConnectionMethodName from "../../../../lib/utils/parseConnectionMethodName";
import transcodingInputURLDefaultValues from "../../../../lib/utils/defaultValues/transcodingInputURLDefaultValues";

import {
  QUICKSTREAM_METHOD_V2,
  INPUT_URL_TYPE,
  SELECT_DEFAULT_AUTO,
  INPUT_WEBCAM_TYPE,
  INPUT_TYPES,
  ENGINE_TYPES,
  INPUT_STREAM_TYPE,
  INPUT_DECKLINK_TYPE,
} from "../../../../lib/utils/constants";

import InputTranscodingSectionTitle from "./InputTranscodingSectionTitle";
import InputTranscodingSectionContainer from "../InputTranscodingSectionContainer";
import InputBackupTranscodingSectionContainer from "../InputBackupTranscodingSectionContainer";
import ProbeDrawerV5 from "../../../ProbeDrawerV5/ProbeDrawerV5";
import InputStreamSourceElement from "../../../InputStreamSourceElement";
import DecklinkElement from "../../../DecklinkElement";
import WebcamElement from "../../../WebcamElement";
import SourceSwitch from "../../../SourceSwitch";
import InputNDIElement from "../../../InputNDIElement";
import InputTranscodingEmergency from "./InputTranscodingEmergency";

import { FormV5Context } from "../../NodeChannelFormV5";

const translations = defineMessages({
  mainInput: {
    id: "InputTranscodingSection.mainInput",
    defaultMessage: "Main input",
  },
  backupInput: {
    id: "InputTranscodingSection.backupInput",
    defaultMessage: "Backup input",
  },
  main: {
    id: "InputTranscodingSection.main",
    defaultMessage: "Main",
  },
  backup: {
    id: "InputTranscodingSection.backup",
    defaultMessage: "Backup",
  },
  switching: {
    id: "InputTranscodingSection.switching",
    defaultMessage: "Switching",
  },
});

function InputTranscodingSection({
  channelConfig,
  detailView,
  handleFinish,
  id,
  requestedStatusText,
  sharedChannelList,
  stundAddress,
  playingSource,
  inputsStatus,
}) {
  const { formatMessage } = useIntl();
  const form = useContext(FormV5Context);
  const { getFieldsValue, resetFields, validateFields, setFieldsValue, getFieldValue } = form;

  const [inputStreamModalVisible, setInputStreamModalVisible] = useState(false);
  const [inputBackupStreamModalVisible, setInputBackupStreamModalVisible] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedInputType, setSelectedInputType] = useState(channelConfig?.mainInput?.type);
  const decklinkInputs = useSelector(NODE_DEVICES_SELECTORS.getDecklinkInputs);

  const selectedBackupInputType = Form.useWatch(["backupInput", "type"], form);
  const selectedMainInputConnectionType = Form.useWatch(["mainInput", "inputStreamCfg", "type"], form);
  const isMainInputQuickstreamMethod = selectedMainInputConnectionType === QUICKSTREAM_METHOD_V2.inQSDirect.value;
  const isInitialValueBackupInputUrlType =
    channelConfig?.backupInput?.type === INPUT_URL_TYPE.value && selectedBackupInputType !== INPUT_URL_TYPE.value;

  const handleOKInput = async () => {
    try {
      await validateFields();
      const formData = getFieldsValue();

      const updatedFormData = { ...channelConfig, ...formData };

      handleFinish(updatedFormData);
      setInputStreamModalVisible(false);
      setInputBackupStreamModalVisible(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("InputTranscodingSection form validation error", error);
    }
  };

  const handleCancelInput = () => {
    resetFields();
    setInputStreamModalVisible(false);
    setInputBackupStreamModalVisible(false);
  };

  const handleShowBackupEditModal = () => {
    if (!channelConfig?.backupInput) {
      setFieldsValue({
        ...channelConfig,
        backupSwitchMsecs: channelConfig?.backupSwitchMsecs || 500,
        backupReturnMsecs: channelConfig?.backupReturnMsecs || 5000,
        backupInput: transcodingInputURLDefaultValues({
          backupInput: channelConfig?.backupInput || {},
        }),
      });
    }

    setInputBackupStreamModalVisible(true);
  };

  const handleShowEditModal = () => {
    if (!channelConfig?.mainInput) {
      setFieldsValue({
        ...channelConfig,
        mainInput: transcodingInputURLDefaultValues({
          input: channelConfig?.mainInput,
        }),
      });
    }

    setInputStreamModalVisible(true);
  };

  const handleShowProbe = async () => {
    setShowDrawer(true);
  };

  const isInput = channelConfig?.mainInput;
  const isBackup = channelConfig?.backupInput;

  const isEngineTranscoding = channelConfig?.type === ENGINE_TYPES.TRANSCODING;

  const isMainQSMethod = !!(channelConfig?.mainInput?.inputStreamCfg?.type === QUICKSTREAM_METHOD_V2.inQSDirect.value);

  const isMainInputURLType = channelConfig?.mainInput?.type === INPUT_URL_TYPE.value;
  const isMainInputWebcamType = channelConfig?.mainInput?.type === INPUT_WEBCAM_TYPE.value;
  const isInputRTMPTypeSelected = channelConfig?.mainInput?.type === INPUT_TYPES.inputRtmp.value;
  const isMainInputDecklinkType = channelConfig?.mainInput?.type === INPUT_DECKLINK_TYPE.value;
  const isInputRTPTypeSelected = channelConfig?.mainInput?.type === INPUT_TYPES.inputMpegtsOverRtp.value;
  const isInputNDITypeSelected = channelConfig?.mainInput?.type === INPUT_TYPES.inputNdi.value;

  const isBackupInputURLType = channelConfig?.backupInput?.type === INPUT_URL_TYPE.value;
  const isBackupInputWebcamType = channelConfig?.backupInput?.type === INPUT_WEBCAM_TYPE.value;
  const isBackupInputRTMPTypeSelected = channelConfig?.backupInput?.type === INPUT_TYPES.inputRtmp.value;
  const isBackupInputDecklinkType = channelConfig?.backupInput?.type === INPUT_DECKLINK_TYPE.value;
  const isBackupInputRTPTypeSelected = channelConfig?.backupInput?.type === INPUT_TYPES.inputMpegtsOverRtp.value;
  const isBackupInputNDITypeSelected = channelConfig?.backupInput?.type === INPUT_TYPES.inputNdi.value;

  const selectedInputDesc = decklinkInputs.find((input) => input.device === channelConfig?.mainInput?.device);
  const selectedBackupDesc = decklinkInputs.find((input) => input.device === channelConfig?.backupInput?.device);
  const parsedInputFormat =
    channelConfig?.mainInput?.format === SELECT_DEFAULT_AUTO.value
      ? SELECT_DEFAULT_AUTO.label
      : channelConfig?.mainInput?.format;
  const parsedBackupFormat =
    channelConfig?.backupInput?.format === SELECT_DEFAULT_AUTO.value
      ? SELECT_DEFAULT_AUTO.label
      : channelConfig?.backupInput?.format;

  return (
    <Card
      title={
        <Row justify="space-between">
          <FormattedMessage id="InputTranscodingSection.inputStream" defaultMessage="Input stream" />
          {isInput && !detailView && (
            <Col>
              <Button
                disabled={isMainQSMethod || isMainInputWebcamType}
                icon={<SearchOutlined />}
                onClick={handleShowProbe}
                type="primary"
              >
                <span>
                  <FormattedMessage id="InputTranscodingSection.probeStream" defaultMessage="Probe stream" />
                </span>
              </Button>
            </Col>
          )}
        </Row>
      }
      extra={
        detailView &&
        isBackup && (
          <SourceSwitch
            channelId={id}
            playingSource={playingSource}
            requestedStatusText={requestedStatusText}
            inputsStatus={inputsStatus}
            type={channelConfig?.type}
          />
        )
      }
    >
      <Row gutter={4} justify="center">
        <ProbeDrawerV5
          type={channelConfig?.type}
          getFieldValue={getFieldValue}
          isBackup={!!isBackup}
          isMainQSMethod={isMainQSMethod}
          setShowDrawer={setShowDrawer}
          showDrawer={showDrawer}
          validateFields={validateFields}
        />
        {isInput && (
          <Col md={24} xl={12} xxl={8}>
            <StyledSpin
              indicator={<> </>}
              spinning={!!playingSource && playingSource !== INPUT_STREAM_TYPE.main && detailView}
              tip={<FormattedMessage id="InputTranscodingSection.inactive" defaultMessage="Inactive" />}
              onClick={() => setInputStreamModalVisible(true)}
            >
              {isMainInputURLType && (
                <InputStreamSourceElement
                  connectionType={channelConfig?.mainInput?.inputStreamCfg?.type}
                  cloudId={channelConfig?.mainInput?.inputStreamCfg?.cloudId}
                  destinationAddress={channelConfig?.mainInput?.inputStreamCfg?.destinationAddress}
                  port={
                    channelConfig?.mainInput?.inputStreamCfg?.destinationPort ||
                    channelConfig?.mainInput?.inputStreamCfg?.localPort
                  }
                  handleClick={handleShowEditModal}
                  interfaceIp={channelConfig?.mainInput?.inputStreamCfg?.interfaceIp}
                  label={formatMessage(translations.main)}
                  title={
                    channelConfig?.mainInput?.inputStreamCfg?.type &&
                    parseConnectionMethodName(channelConfig?.mainInput?.inputStreamCfg?.type)
                  }
                  incomplete={channelConfig?.mainInput?.inputStreamCfg?.incomplete}
                />
              )}
              {isInputRTMPTypeSelected && (
                <InputStreamSourceElement
                  connectionType={channelConfig?.mainInput?.inputStreamCfg?.type}
                  handleClick={handleShowEditModal}
                  label={formatMessage(translations.main)}
                  title={INPUT_TYPES.inputRtmp.label}
                  url={channelConfig?.mainInput?.url}
                  urlKey={channelConfig?.mainInput?.key}
                  incomplete={channelConfig?.mainInput?.incomplete}
                />
              )}
              {isInputRTPTypeSelected && (
                <InputStreamSourceElement
                  connectionType={channelConfig?.mainInput?.inputStreamCfg?.type}
                  handleClick={handleShowEditModal}
                  ip={channelConfig?.mainInput?.ip}
                  label={formatMessage(translations.main)}
                  port={channelConfig?.mainInput?.port}
                  title={INPUT_TYPES.inputMpegtsOverRtp.label}
                  incomplete={channelConfig?.mainInput?.incomplete}
                />
              )}
              {isInputNDITypeSelected && (
                <InputNDIElement
                  label={formatMessage(translations.main)}
                  handleClick={handleShowEditModal}
                  streamSource={channelConfig?.mainInput?.streamSource}
                  title={INPUT_TYPES.inputNdi.label}
                  incomplete={channelConfig?.mainInput?.incomplete}
                />
              )}
              {isMainInputWebcamType && (
                <WebcamElement
                  bitsPerSample={channelConfig?.mainInput?.bitsPerSample}
                  channels={channelConfig?.mainInput?.channels}
                  handleClick={handleShowEditModal}
                  label={formatMessage(translations.main)}
                  sampleRate={channelConfig?.mainInput?.sampleRate}
                  audioTitle={channelConfig?.mainInput?.micDevice}
                  videoTitle={channelConfig?.mainInput?.webcamDevice}
                  width={channelConfig?.mainInput?.width}
                  height={channelConfig?.mainInput?.height}
                  fps={channelConfig?.mainInput?.fps}
                  incomplete={channelConfig?.mainInput?.incomplete}
                  title={INPUT_TYPES.inputWebcam.label}
                />
              )}
              {isMainInputDecklinkType && (
                <DecklinkElement
                  label={formatMessage(translations.main)}
                  handleClick={handleShowEditModal}
                  selectedInputDesc={selectedInputDesc?.desc}
                  format={parsedInputFormat}
                  incomplete={channelConfig?.mainInput?.incomplete}
                  title={INPUT_TYPES.inputDecklink.label}
                  videoSource={
                    channelConfig?.mainInput?.videoSource === SELECT_DEFAULT_AUTO.value
                      ? SELECT_DEFAULT_AUTO.label
                      : channelConfig?.mainInput?.videoSource
                  }
                  audioSource={
                    channelConfig?.mainInput?.audioSource === SELECT_DEFAULT_AUTO.value
                      ? SELECT_DEFAULT_AUTO.label
                      : channelConfig?.mainInput?.audioSource
                  }
                  audioChannels={channelConfig?.mainInput?.audioChannels}
                />
              )}
            </StyledSpin>
          </Col>
        )}
        {channelConfig?.backupInput && (
          <Col md={24} xl={12} xxl={8}>
            <StyledSpin
              indicator={<> </>}
              spinning={!!playingSource && playingSource !== INPUT_STREAM_TYPE.backup && detailView}
              tip={<FormattedMessage id="InputTranscodingSection.inactive" defaultMessage="Inactive" />}
              onClick={handleShowBackupEditModal}
            >
              {isBackupInputURLType && (
                <InputStreamSourceElement
                  connectionType={channelConfig?.backupInput?.inputStreamCfg?.inputStreamCfg?.type}
                  destinationAddress={channelConfig?.backupInput?.inputStreamCfg?.destinationAddress}
                  port={
                    channelConfig?.backupInput?.inputStreamCfg?.destinationPort ||
                    channelConfig?.backupInput?.inputStreamCfg?.localPort
                  }
                  handleClick={handleShowBackupEditModal}
                  interfaceIp={channelConfig?.backupInput?.inputStreamCfg?.interfaceIp}
                  label={formatMessage(translations.backup)}
                  title={
                    channelConfig?.backupInput?.inputStreamCfg?.type &&
                    parseConnectionMethodName(channelConfig?.backupInput?.inputStreamCfg?.type)
                  }
                  incomplete={channelConfig?.backupInput?.incomplete}
                />
              )}
              {isBackupInputRTMPTypeSelected && (
                <InputStreamSourceElement
                  connectionType={channelConfig?.backupInput?.inputStreamCfg?.type}
                  handleClick={() => {}}
                  label={formatMessage(translations.backup)}
                  title={INPUT_TYPES.inputRtmp.label}
                  url={channelConfig?.backupInput?.url}
                  urlKey={channelConfig?.backupInput?.key}
                  incomplete={channelConfig?.backupInput?.incomplete}
                />
              )}
              {isBackupInputRTPTypeSelected && (
                <InputStreamSourceElement
                  connectionType={channelConfig?.backupInput?.inputStreamCfg?.type}
                  handleClick={() => {}}
                  ip={channelConfig?.backupInput?.ip}
                  label={formatMessage(translations.main)}
                  port={channelConfig?.backupInput?.port}
                  title={INPUT_TYPES.inputMpegtsOverRtp.label}
                  incomplete={channelConfig?.backupInput?.incomplete}
                />
              )}
              {isBackupInputNDITypeSelected && (
                <InputNDIElement
                  label={formatMessage(translations.main)}
                  handleClick={() => {}}
                  streamSource={channelConfig?.backupInput?.streamSource}
                  title={INPUT_TYPES.inputNdi.label}
                  incomplete={channelConfig?.backupInput?.incomplete}
                />
              )}
              {isBackupInputWebcamType && (
                <WebcamElement
                  bitsPerSample={channelConfig?.backupInput?.bitsPerSample}
                  channels={channelConfig?.backupInput?.channels}
                  handleClick={() => {}}
                  label={formatMessage(translations.backup)}
                  sampleRate={channelConfig?.backupInput?.sampleRate}
                  audioTitle={channelConfig?.backupInput?.micDevice}
                  videoTitle={channelConfig?.backupInput?.webcamDevice}
                  width={channelConfig?.backupInput?.width}
                  height={channelConfig?.backupInput?.height}
                  fps={channelConfig?.backupInput?.fps}
                  incomplete={channelConfig?.backupInput?.incomplete}
                  title={INPUT_TYPES.inputWebcam.label}
                />
              )}
              {isBackupInputDecklinkType && (
                <DecklinkElement
                  label={formatMessage(translations.backup)}
                  handleClick={() => {}}
                  selectedInputDesc={selectedBackupDesc?.desc}
                  format={parsedBackupFormat}
                  incomplete={channelConfig?.backupInput?.incomplete}
                  title={INPUT_TYPES.inputDecklink.label}
                  videoSource={
                    channelConfig?.backupInput?.videoSource === SELECT_DEFAULT_AUTO.value
                      ? SELECT_DEFAULT_AUTO.label
                      : channelConfig?.backupInput?.videoSource
                  }
                  audioSource={
                    channelConfig?.backupInput?.audioSource === SELECT_DEFAULT_AUTO.value
                      ? SELECT_DEFAULT_AUTO.label
                      : channelConfig?.backupInput?.audioSource
                  }
                  audioChannels={channelConfig?.backupInput?.audioChannels}
                />
              )}
            </StyledSpin>
          </Col>
        )}
        {isEngineTranscoding && (
          <InputTranscodingEmergency
            form={form}
            emergencyFile={channelConfig?.emergencyFile}
            emergencySwitchMsecs={channelConfig?.emergencySwitchMsecs}
            emergencyReturnMsecs={channelConfig?.emergencyReturnMsecs}
            backupSwitchMsecs={channelConfig?.backupSwitchMsecs}
            backupReturnMsecs={channelConfig?.backupReturnMsecs}
            handleOKInput={handleOKInput}
            detailView={detailView}
            playingSource={playingSource}
            label={formatMessage(translations.switching)}
            title={
              <>
                <FormattedMessage id="InputTranscodingSection.emergencyModeTile" defaultMessage="Emergency mode" />
                {": "}
                {parseEmergencyModeName(channelConfig?.emergencyMode)}
              </>
            }
          />
        )}
        {!isInput && !detailView && (
          <Button type="primary" style={{ width: "100%", margin: "10px" }} onClick={handleShowEditModal}>
            <PlusOutlined />{" "}
            <FormattedMessage id="InputTranscodingSection.addMainInput" defaultMessage="Add main input" />
          </Button>
        )}

        {!isBackup && !detailView && (
          <Button
            type="dashed"
            style={{ width: "100%", margin: "10px" }}
            onClick={handleShowBackupEditModal}
            disabled={isMainInputQuickstreamMethod || !isInput}
          >
            <PlusOutlined />{" "}
            <FormattedMessage id="InputTranscodingSection.addBackupInput" defaultMessage="Add backup input" />
          </Button>
        )}
      </Row>
      {inputStreamModalVisible && (
        <StyledModal
          zIndex={100}
          centered
          title={<FormattedMessage id="InputTranscodingSection.mainInputStreamForm" defaultMessage="Main Input" />}
          open={inputStreamModalVisible}
          onCancel={handleCancelInput}
          footer={
            detailView ? null : (
              <>
                <Button key="back" onClick={handleCancelInput}>
                  <FormattedMessage id="general.cancel" defaultMessage="Cancel" />
                </Button>
                <Button key="ok" type="primary" onClick={handleOKInput} icon={<SaveOutlined />}>
                  <span>
                    <FormattedMessage id="general.save" defaultMessage="Save" />
                  </span>
                </Button>
              </>
            )
          }
        >
          <InputTranscodingSectionContainer
            isInput={!!channelConfig?.mainInput}
            channelList={sharedChannelList}
            disabled={detailView}
            initialValue={channelConfig?.mainInput}
            setSelectedInputType={setSelectedInputType}
            selectedInputType={selectedInputType}
            sharedChannelList={sharedChannelList}
            stundAddress={stundAddress}
          />
        </StyledModal>
      )}
      {inputBackupStreamModalVisible && (
        <StyledModal
          zIndex={100}
          centered
          title={
            <InputTranscodingSectionTitle
              setInputBackupStreamModalVisible={setInputBackupStreamModalVisible}
              handleFinish={handleFinish}
              channelConfig={channelConfig}
              disabled={detailView}
            />
          }
          open={inputBackupStreamModalVisible}
          onCancel={handleCancelInput}
          footer={
            detailView ? null : (
              <>
                <Button key="back" onClick={handleCancelInput}>
                  <FormattedMessage id="general.cancel" defaultMessage="Cancel" />
                </Button>
                {isInitialValueBackupInputUrlType && (
                  <Popconfirm
                    title={
                      <StyledPopconfirmText>
                        <FormattedMessage
                          id="InputTranscodingSection.beAware"
                          // eslint-disable-next-line max-len
                          defaultMessage="Be aware that this accepting this change you will overwrite the all old input configuration (including input backup), and all direct outputs"
                        />
                      </StyledPopconfirmText>
                    }
                    icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                    onConfirm={handleOKInput}
                    okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
                    cancelText={<FormattedMessage id="general.no" defaultMessage="No" />}
                  >
                    <Button key="ok" type="primary">
                      <FormattedMessage id="general.ok" defaultMessage="OK" />
                    </Button>
                  </Popconfirm>
                )}
                {!isInitialValueBackupInputUrlType && (
                  <Button key="ok" type="primary" onClick={handleOKInput} icon={<SaveOutlined />}>
                    <span>
                      <FormattedMessage id="general.save" defaultMessage="Save" />
                    </span>
                  </Button>
                )}
              </>
            )
          }
        >
          <InputBackupTranscodingSectionContainer
            channelList={sharedChannelList}
            disabled={detailView}
            initialValue={channelConfig?.backupInput}
            sharedChannelList={sharedChannelList}
            stundAddress={stundAddress}
          />
        </StyledModal>
      )}
    </Card>
  );
}

const StyledModal = styled(Modal)`
  min-width: 75%;
`;

const StyledSpin = styled(Spin)`
  .ant-spin-text {
    top: 30% !important;
    font-weight: bold;
  }
`;

const StyledPopconfirmText = styled.div`
  max-width: 350px;
`;

InputTranscodingSection.propTypes = {
  channelConfig: PropTypes.object,
  detailView: PropTypes.bool,
  handleFinish: PropTypes.func.isRequired,
  id: PropTypes.string,
  requestedStatusText: PropTypes.string,
  sharedChannelList: PropTypes.array,
  stundAddress: PropTypes.string,
  playingSource: PropTypes.string.isRequired,
  inputsStatus: PropTypes.arrayOf(
    PropTypes.shape({
      connectionInfo: PropTypes.string.isRequired,
      errorCode: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      permissionId: PropTypes.number.isRequired,
      threadStatus: PropTypes.number.isRequired,
      threadStatusText: PropTypes.string.isRequired,
    })
  ).isRequired,
};

InputTranscodingSection.defaultProps = {
  channelConfig: null,
  detailView: null,
  id: null,
  requestedStatusText: null,
  sharedChannelList: null,
  stundAddress: null,
};

export default InputTranscodingSection;
