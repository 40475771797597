import React from "react";
import styled from "styled-components";
import { Modal, Row, Col, Form } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import ChannelName from "../../../../components/NodeChannelFormV5/GeneralSection/ChannelName";
import ChannelDescription from "../../../../components/NodeChannelFormV5/GeneralSection/ChannelDescription";
import InputTextFiled from "../../../../components/Fields/InputTextField";
import NewNodeChannelType from "../../../../components/NewNodeChannelType";

import {
  FORM_VERSION_V5,
  MESSAGE_TYPE,
  ENGINE_TYPES,
  DEFAULT_GLOBAL_VIDEO,
  GLOBAL_CODEC_AUDIO_SAMPLE_FORMATS,
  GLOBAL_CODEC_AUDIO_SAMPLE_RATE,
  DEFAULT_SWITCH_SETTINGS,
  DEFAULT_EMERGENCY_MODE,
} from "../../../../lib/utils/constants";
import { actions as LOADING_ACTIONS } from "../../../../ducks/loadingData";
import { selectors as NODE_SELECTORS } from "../../../../ducks/node";

import MQTTService from "../../../../services/mqtt";
import store from "../../../../store";

const translations = defineMessages({
  channelName: {
    id: "BasicModeGeneralSection.channelName",
    defaultMessage: "Channel name",
  },
});

function NewNodeChannelModalV5({ setVisibleNewNodeChannelForm, visibleNewNodeChannelForm }) {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const { getFieldValue, validateFields, resetFields, scrollToField } = form;
  const cwid = useSelector(NODE_SELECTORS.getNodeCwid);

  const handleOK = async () => {
    try {
      await validateFields();
      const formData = getFieldValue();

      const isTranscodingEngine = formData?.type === ENGINE_TYPES.TRANSCODING;

      if (isTranscodingEngine) {
        const parsedFormData = {
          ...formData,
          globalVideoFormat: DEFAULT_GLOBAL_VIDEO,
          globalAudioFormats: [
            {
              sampleFormat: GLOBAL_CODEC_AUDIO_SAMPLE_FORMATS[2].value,
              sampleRate: GLOBAL_CODEC_AUDIO_SAMPLE_RATE[0].value,
              channels: 2,
            },
          ],
          ...DEFAULT_SWITCH_SETTINGS,
          ...DEFAULT_EMERGENCY_MODE,
        };

        MQTTService.sendMessageV5({
          topic: `node/${cwid}/in`,
          message: {
            msgType: MESSAGE_TYPE.ADD_NODE_CHANNEL,
            channelConfig: JSON.stringify(parsedFormData),
          },
        });
      } else {
        MQTTService.sendMessageV5({
          topic: `node/${cwid}/in`,
          message: {
            msgType: MESSAGE_TYPE.ADD_NODE_CHANNEL,
            channelConfig: JSON.stringify(formData),
          },
        });
      }

      store.dispatch(LOADING_ACTIONS.SET_LOADING("addNodeChannel"));
      setVisibleNewNodeChannelForm(false);
    } catch (error) {
      scrollToField(error.errorFields[0].name);
    }
  };

  const handleCancel = () => {
    resetFields();
    setVisibleNewNodeChannelForm(false);
  };

  const initialFormValues = {
    version: FORM_VERSION_V5,
    type: "direct",
  };

  return (
    <Form form={form} initialValues={initialFormValues}>
      <StyledModal
        zIndex={100}
        centered
        title={<FormattedMessage id="NewNodeChannelModalV5.newChannel" defaultMessage="New channel" />}
        open={visibleNewNodeChannelForm}
        onOk={handleOK}
        onCancel={handleCancel}
        forceRender
      >
        <Row gutter={24}>
          <Col span={12}>
            <ChannelName name="name" label={formatMessage(translations.channelName)} />
          </Col>
          <Col span={12}>
            <NewNodeChannelType />
          </Col>
          <Col span={24}>
            <ChannelDescription />
          </Col>
          <StyledHiddenCol span={4}>
            <InputTextFiled name="version" />
          </StyledHiddenCol>
        </Row>
      </StyledModal>
    </Form>
  );
}

const StyledModal = styled(Modal)`
  min-width: 75%;
`;

const StyledHiddenCol = styled(Col)`
  visibility: hidden;
  display: none;
`;

NewNodeChannelModalV5.propTypes = {
  setVisibleNewNodeChannelForm: PropTypes.func.isRequired,
  visibleNewNodeChannelForm: PropTypes.bool.isRequired,
};

export default NewNodeChannelModalV5;
