import {
  CONNECTION_METHOD_INPUT,
  DEFAULT_VALUES,
  INPUT_URL_TYPE,
  QUICKSTREAM_METHOD_V2,
  SELECT_DEFAULT_AUTO,
} from "../constants";

// const parsedCloudId = (cloudId, sharedChannelList) => {
//   if (cloudId && sharedChannelList && sharedChannelList.length > 0) {
//     const selectedChannelData = sharedChannelList.find((channel) => +cloudId === +channel.cloudId);

//     return selectedChannelData?.permissionId;
//   }

//   return null;
// };

// eslint-disable-next-line no-unused-vars
const inputURLDefaultValues = ({ input, stundAddress, sharedChannelList }) => {
  return {
    ...input,
    // ...(input?.cloudId && { cloudId: parsedCloudId({ cloudId: input?.cloudId, sharedChannelList }) }),
    type: INPUT_URL_TYPE.value,
    mainConnection: {
      ...input?.mainConnection,
      type: input?.mainConnection?.type || CONNECTION_METHOD_INPUT.inUdpMulticast.value,
      interfaceIp: input?.mainConnection?.interfaceIp || SELECT_DEFAULT_AUTO.value,
      destinationPort: input?.mainConnection?.destinationPort || DEFAULT_VALUES.PORT,
    },
    switchInputBackup: input?.switchInputBackup || DEFAULT_VALUES.SWITCH_INPUT_BACKUP,
  };
};

export const DEFAULT_IN_QS_DIRECT = {
  type: QUICKSTREAM_METHOD_V2.inQSDirect.value,
  srtLatency: DEFAULT_VALUES.SRT_LATENCY,
  srtOverhead: DEFAULT_VALUES.OVERHEAD,
  interfaceIp: SELECT_DEFAULT_AUTO.value,
  localPort: DEFAULT_VALUES.LOCALPORT,
  encryptionEnabled: false,
};

export const DEFAULT_IN_UDP_UNICAST = {
  type: CONNECTION_METHOD_INPUT.inUdpUnicast.value,
  interfaceIp: SELECT_DEFAULT_AUTO.value,
  // localPort: DEFAULT_VALUES.LOCALPORT_INPUT_UNICAST,
};

export const DEFAULT_IN_UDP_MULTICAST = {
  type: CONNECTION_METHOD_INPUT.inUdpMulticast.value,
  interfaceIp: SELECT_DEFAULT_AUTO.value,
  destinationPort: DEFAULT_VALUES.PORT,
};

export const DEFAULT_IN_SRT_LISTENER = {
  type: CONNECTION_METHOD_INPUT.inSrtListener.value,
  interfaceIp: SELECT_DEFAULT_AUTO.value,
  // localPort: DEFAULT_VALUES.PORT,
  srtLatency: DEFAULT_VALUES.SRT_LATENCY,
  srtOverhead: DEFAULT_VALUES.OVERHEAD,
  encryptionEnabled: false,
  setStreamId: false,
};

export const DEFAULT_IN_SRT_CALLER = {
  ...DEFAULT_IN_SRT_LISTENER,
  type: CONNECTION_METHOD_INPUT.inSrtCaller.value,
  localPort: DEFAULT_VALUES.LOCALPORT,
  destinationAddress: DEFAULT_VALUES.IP,
  destinationPort: DEFAULT_VALUES.PORT,
};

export const DEFAULT_INPUT_BACKUP_VALUES = {
  type: CONNECTION_METHOD_INPUT.inUdpMulticast.value,
  interfaceIp: SELECT_DEFAULT_AUTO.value,
  destinationPort: DEFAULT_VALUES.PORT,
};

export const DEFAULT_TRIGGER_BITRATE = {
  triggerBitrateLevel: DEFAULT_VALUES.TRIGGER_BITRATE_LEVEL,
  triggerBitrateTimeSecs: DEFAULT_VALUES.TRIGGER_BITRATE_SECS,
};

export const DEFAULT_TRIGGER_CONNECTION = {
  triggerConnectionTimeSecs: DEFAULT_VALUES.TRIGGER_BITRATE_TIME_SECS,
};

export const DEFAULT_INPUT_BACKUP_TRIGGERS = {
  triggerBitrateEnabled: DEFAULT_VALUES.TRIGGER_INIT,
  triggerBitrateLevel: DEFAULT_VALUES.TRIGGER_BITRATE_LEVEL,
  triggerBitrateTimeSecs: DEFAULT_VALUES.TRIGGER_BITRATE_SECS,
  triggerConnectionTimeEnabled: DEFAULT_VALUES.TRIGGER_INIT,
  triggerConnectionTimeSecs: DEFAULT_VALUES.TRIGGER_BITRATE_TIME_SECS,
  triggerDisconnectEnabled: DEFAULT_VALUES.TRIGGER_INIT,
};

export default inputURLDefaultValues;
