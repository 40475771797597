import React, { useState, useCallback, useEffect } from "react";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Card, Table, Row, Popconfirm, Button, Empty, Affix } from "antd";
import { connect } from "react-redux";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import CloudChannelsServices from "../../services/cloudChannels";

import { errorNotification, successNotification } from "../../lib/utils/notification";

import { selectors as CLOUD_CHANNELS_SELECTORS } from "../../ducks/cloudChannels";
import { selectors as ACCOUNT_SELECTORS } from "../../ducks/account";

import ChannelDetailViewStatus from "./ChannelDetailViewStatus";
import ChannelDetailViewCardTitle from "./ChannelDetailViewCardTitle";
import GlobalErrorBoundaries from "../../components/ErrorBoundaries/GlobalErrorBoundaries";
import HubChannelShare from "./HubChannelShare";
import HubChannelInvites from "./HubChannelInvites";

import { CLOUD_CHANNEL_TYPE } from "../../lib/utils/constants";
import themeColor from "../../lib/style/theme";

const translations = defineMessages({
  channel: {
    id: "HubChannelDetailView.channel",
    defaultMessage: "Channel:",
  },
  actions: {
    id: "general.actions",
    defaultMessage: "Actions",
  },
  shareRequired: {
    id: "HubChannelDetailView.shareRequired",
    defaultMessage: "Field required to share channel",
  },
});

const HubChannelDetailView = ({ allChannelsList, ownChannelsList, username }) => {
  const { id, type } = useParams();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // if (type === CLOUD_CHANNEL_TYPE.all && allChannelsList.length === 0) {
    //   CloudChannelsServices.getAllChannels({ errorNotification: errorNotification(formatMessage) });
    // }

    if (type === CLOUD_CHANNEL_TYPE.own && ownChannelsList.length === 0) {
      CloudChannelsServices.getOwnChannels({ errorNotification: errorNotification(formatMessage) });
    }
  }, [type, allChannelsList.length, ownChannelsList.length, formatMessage]);

  const [permissions, setPermissions] = useState([]);

  const fetchChannels = useCallback(async () => {
    const channelPermissions = await CloudChannelsServices.getChannelPermissions(id, {
      errorNotification: errorNotification(formatMessage),
    });

    setPermissions(channelPermissions);
  }, [formatMessage, id]);

  const removePermission = async (permissionId) => {
    setLoading(true);
    const successResponse = await CloudChannelsServices.removeChannelPermission(permissionId, {
      errorNotification: errorNotification(formatMessage),
      successNotification: successNotification(formatMessage),
    });
    if (successResponse) {
      const updatedPermissions = permissions.filter((permission) => permission.permissionId !== permissionId);
      setPermissions(updatedPermissions);
    }

    setLoading(false);
  };

  const cloudChannels = type === CLOUD_CHANNEL_TYPE.all ? allChannelsList : ownChannelsList;
  const channelData = cloudChannels && cloudChannels.find((channel) => channel.id === +id);
  const isChannelOwner = channelData && channelData.username === username;

  useEffect(() => {
    fetchChannels();
  }, []);

  const adminColumns = [
    {
      title: <FormattedMessage id="HubChannelDetailView.username" defaultMessage="Username" />,
      dataIndex: "username",
      key: "username",
    },
  ];

  const columns = [
    {
      title: <FormattedMessage id="HubChannelDetailView.username" defaultMessage="Username" />,
      dataIndex: "username",
      key: "username",
    },
    {
      title: <FormattedMessage id="HubChannelDetailView.permissionName" defaultMessage="Share name" />,
      dataIndex: "description",
      key: "description",
    },
    {
      title: <FormattedMessage id="HubChannelDetailView.status" defaultMessage="Status" />,
      dataIndex: "status",
      key: "status",
      render: (status) => <ChannelDetailViewStatus status={status} />,
      width: 250,
      align: "center",
    },
    {
      key: "actions",
      width: 120,
      align: "center",
      title: formatMessage(translations.actions),
      render: (text, { permissionId }) => (
        <Popconfirm
          title={
            <FormattedMessage
              id="HubChannelDetailView.confirmRemove"
              defaultMessage="Are you sure, you want to remove permission for this user?"
            />
          }
          icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          onConfirm={() => removePermission(permissionId)}
          okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
          cancelText={<FormattedMessage id="general.no" defaultMessage="No" />}
          placement="topLeft"
        >
          <Button type="primary" danger icon={<DeleteOutlined />} disabled={loading} loading={loading}>
            <span>
              <FormattedMessage id="general.remove" defaultMessage="Remove" />
            </span>
          </Button>
        </Popconfirm>
      ),
    },
  ];

  if (!channelData) {
    return (
      <Empty
        description={
          <FormattedMessage id="HubChannelDetailView.waitingForStatistics" defaultMessage="Missing channel data" />
        }
      />
    );
  }

  return (
    <GlobalErrorBoundaries>
      <Card title={<ChannelDetailViewCardTitle channelData={channelData} />}>
        <StyledCard title={<FormattedMessage id="HubChannelDetailView.sharedWith" defaultMessage="Shared with" />}>
          <StyledTable
            columns={isChannelOwner ? columns : adminColumns}
            dataSource={permissions}
            pagination={false}
            bordered
            role="table"
            rowKey={(record) => record.permissionId}
            rowClassName={(record) => (record.username ? "active" : "pending")}
            scroll={{ x: "calc(100vh - 250px)" }}
          />
        </StyledCard>
        {isChannelOwner && (
          <Affix offsetBottom={0}>
            <Card
              title={
                <FormattedMessage id="handleFinishInvitation.shareHubChannel" defaultMessage="Share hub channel" />
              }
            >
              <Row gutter={[24, 24]}>
                <HubChannelShare fetchChannels={fetchChannels} channelData={channelData} />
                <HubChannelInvites fetchChannels={fetchChannels} channelData={channelData} />
              </Row>
            </Card>
          </Affix>
        )}
      </Card>
    </GlobalErrorBoundaries>
  );
};

const StyledTable = styled(Table)`
  .pending {
    background: ${themeColor.paddingInvitation};
  }
`;

const StyledCard = styled(Card)`
  margin-bottom: 15px;
`;

const mapStateToProps = (state) => ({
  username: ACCOUNT_SELECTORS.getUser(state),
  ownChannelsList: CLOUD_CHANNELS_SELECTORS.getOwnChannels(state),
  allChannelsList: CLOUD_CHANNELS_SELECTORS.getAllChannels(state),
});

HubChannelDetailView.propTypes = {
  ownChannelsList: PropTypes.arrayOf(
    PropTypes.shape({
      cloudId: PropTypes.number.isRequired,
      channelName: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      isEncrypted: PropTypes.number,
      public: PropTypes.bool.isRequired,
      userId: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  allChannelsList: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        cloudId: PropTypes.number.isRequired,
        channelName: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        userId: PropTypes.number.isRequired,
      })
    ),
    PropTypes.array,
  ]).isRequired,
  username: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, null)(HubChannelDetailView);
