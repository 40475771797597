import React, { useState, useContext } from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";

import { CONNECTION_METHOD_INPUT } from "../../../../lib/utils/constants";
import { INPUT_BACKUP_TYPE, INPUT_BACKUP_TRIGGERS_TYPE } from "../../../../lib/utils/types/inputURLTypes";
import { required } from "../../../../lib/utils/formValidation";

import InputTranscodingConnectionMethod from "../../../InputTranscodingConnectionMethod";
import InputStreamUdpSection from "../../EngineDirect/InputStreamSection/InputStreamUdpSection";
import InputStreamSrtSection from "../../EngineDirect/InputStreamSection/InputStreamSrtSection";
import InputStreamVideoStreamSelector from "../InputTranscodingSectionContainer/InputStreamVideoSelector";
import InputStreamAudioSelector from "../InputTranscodingSectionContainer/InputStreamAudioSelector";
import InputStreamSectionBackupTriggers from "../../InputStreamSectionBackupTriggers";
import StreamIdFields from "../../../StreamIdFields";

import AdvancedSettings from "../../../AdvancedSettings";
import InputNumberField from "../../../Fields/InputNumberField";
import InputTextField from "../../../Fields/InputTextField";
import PassphraseField from "../../../Fields/PassphraseField";
import { PortField } from "../../../Fields/Fields";
import LocalPortLabel from "../../../LocalPortLabel";
import OverheadBandwidthLabel from "../../Labels/OverheadBandwidthLabel";

import { FormV5Context } from "../../NodeChannelFormV5";

const SRT_METHODS = [CONNECTION_METHOD_INPUT.inSrtListener.value, CONNECTION_METHOD_INPUT.inSrtCaller.value];
const UDP_METHODS = [CONNECTION_METHOD_INPUT.inUdpUnicast.value, CONNECTION_METHOD_INPUT.inUdpMulticast.value];

function InputTranscodingStreamSectionBackup({ disabled, initialValue }) {
  const form = useContext(FormV5Context);
  const { getFieldValue, setFieldsValue } = form;

  const backupConnectionMethodValue = getFieldValue(["backupInput", "inputStreamCfg", "type"]);

  const [backupConnectionMethod, setBackupConnectionMethod] = useState(
    initialValue?.backupConnection?.type || backupConnectionMethodValue || CONNECTION_METHOD_INPUT.inUdpUnicast.value
  );

  const isUDPBackupMethod = UDP_METHODS.includes(backupConnectionMethod);
  const isSRTBackupMethod = SRT_METHODS.includes(backupConnectionMethod);
  const isBackupCallerMode = backupConnectionMethod === CONNECTION_METHOD_INPUT.inSrtCaller.value;

  return (
    <Row type="flex" justify="start" gutter={24}>
      <InputNumberField name={["backupInput", "inputStreamCfg", "inputId"]} disabled={disabled} hidden />
      <InputTextField
        name={["backupInput", "inputStreamCfg", "inputName"]}
        disabled={disabled}
        required={false}
        hidden
      />
      <Col span={24}>
        <InputTranscodingConnectionMethod
          getFieldValue={getFieldValue}
          setFieldsValue={setFieldsValue}
          disabled={disabled}
          name={["backupInput", "inputStreamCfg", "type"]}
          setConnectionMethod={setBackupConnectionMethod}
          isBackup
        />
      </Col>

      {isUDPBackupMethod && (
        <InputStreamUdpSection
          connectionMethod={backupConnectionMethod}
          disabled={disabled}
          initialValue={initialValue?.backupInput}
          prefix={["backupInput", "inputStreamCfg"]}
        />
      )}
      {isSRTBackupMethod && (
        <InputStreamSrtSection
          connectionMethod={backupConnectionMethod}
          disabled={disabled}
          getFieldValue={getFieldValue}
          initialValue={initialValue?.backupInput}
          prefix={["backupInput", "inputStreamCfg"]}
          setFieldsValue={setFieldsValue}
          isBackup
          form={form}
        />
      )}
      <Col span={24}>
        <AdvancedSettings>
          {isSRTBackupMethod && (
            <Row gutter={24}>
              {isBackupCallerMode && (
                <Col md={24} lg={12}>
                  <PortField
                    name={["backupInput", "inputStreamCfg", "localPort"]}
                    disabled={disabled}
                    label={<LocalPortLabel noTooltip={false} />}
                    min={0}
                  />
                </Col>
              )}
              <Col md={24} lg={12}>
                <InputNumberField
                  disabled={disabled}
                  name={["backupInput", "inputStreamCfg", "srtOverhead"]}
                  label={OverheadBandwidthLabel()}
                  rules={[required]}
                  min={5}
                  max={100}
                />
              </Col>
              <StreamIdFields prefix={["backupInput", "inputStreamCfg"]} form={form} disabled={disabled} />
              <Col md={24} lg={24}>
                <PassphraseField
                  disabled={disabled}
                  getFieldValue={getFieldValue}
                  prefix={["backupInput", "inputStreamCfg"]}
                  setFieldsValue={setFieldsValue}
                  type="backupInput"
                  form={form}
                />
              </Col>
            </Row>
          )}
          <InputStreamVideoStreamSelector prefix={["backupInput", "videoStreamSelector"]} disabled={disabled} />
          <InputStreamAudioSelector disabled={disabled} prefix={["backupInput", "audioStreamSelectors"]} />
          <InputStreamSectionBackupTriggers
            disabled={disabled}
            prefix={["backupInput", "backupTriggers"]}
            form={form}
          />
        </AdvancedSettings>
      </Col>
    </Row>
  );
}

InputTranscodingStreamSectionBackup.propTypes = {
  disabled: PropTypes.bool,
  initialValue: PropTypes.shape({
    ...INPUT_BACKUP_TYPE,
    ...INPUT_BACKUP_TRIGGERS_TYPE,
  }),
};

InputTranscodingStreamSectionBackup.defaultProps = {
  disabled: null,
  initialValue: null,
};

export default InputTranscodingStreamSectionBackup;
