import React from "react";
import { Route, Routes, Navigate, unstable_HistoryRouter as HistoryRouter } from "react-router-dom";

import { history } from "store";

import Registration from "../pages/Registration";
import PasswordForgot from "../pages/PasswordForgot";
import AccountActivation from "../pages/AccountActivation";

import PrivateRoutes from "../components/PrivateRoutes";
import LoginForm from "../components/LoginForm";
import NavigationLayout from "../components/NavigationLayout";
import ChangePasswordForm from "../components/ChangePasswordForm";
import Login2FA from "../pages/Login2FA";
import Auth2FA from "../pages/Auth2FA";

import Account from "../pages/Account";
import AccountsList from "../pages/Admin/AccountsList";
import LicenseContainer from "../pages/LicenseContainer";
import CloudLicensesList from "../pages/Admin/License/CloudLicensesList";
import CloudUserLicensesList from "../pages/Admin/License/CloudUserLicensesList";
import NewLicenseForm from "../pages/Admin/License/NewLicenseForm";
import HubChannelsList from "../pages/Channels";
import HubChannelDetailView from "../pages/HubChannelDetailView";
import NodeDashboard from "../pages/Node/NodeDashboard";
import NodeChannelEdit from "../pages/NodeChannelEdit";
import NodeSystem from "../pages/Node/NodeSystem";
import NodeLicenseList from "../pages/Node/NodeLicenseList";
import NodesListView from "../pages/NodesListView";
import ShareNodeForm from "../pages/ShareNodeForm";
import NotificationsPage from "../pages/NotificationsPage";
import OrganizationPage from "../pages/OrganizationPage";
import OrganizationsPage from "../pages/Admin/Organizations";
import OrganizationPageAssign from "../pages/OrganizationPageAssign";
import UserDetailView from "../pages/UserDetailView";
import CloudNodes from "../pages/CloudNodes";
import NodeChannelDetailView from "../pages/Node/NodeChannelDetailView";
import Dashboard from "../pages/Dashboard";

const MainRouters = () => (
  <HistoryRouter history={history}>
    <Routes>
      <Route path="/login" element={<LoginForm />} />
      <Route path="/login/2fa/send/:login" element={<Login2FA />} />
      <Route path="/auth/2fa/:token" element={<Auth2FA />} />
      <Route path="/register" element={<Registration />} />
      <Route path="/register/:token" element={<Registration />} />
      <Route path="/password/forgot" element={<PasswordForgot />} />
      <Route path="/account/activation/:activationToken" element={<AccountActivation />} />
      <Route path="/account/password/reset/:resetLink" element={<ChangePasswordForm />} />
      <Route element={<PrivateRoutes />}>
        <Route element={<NavigationLayout />}>
          <Route index path="/" element={<Dashboard />} />
          <Route path="/account" element={<Account />} />
          <Route path="/qs-hub/organization" element={<OrganizationPage />} />
          <Route path="/organization/edit" element={<OrganizationPageAssign />} />
          <Route path="/users/:username" element={<UserDetailView />} />
          <Route path="/admin/accounts" element={<AccountsList />} />
          <Route path="/qs-hub/organizations" element={<OrganizationsPage />} />
          <Route path="/licenses" element={<LicenseContainer />} />
          <Route path="/cloud/licenses" element={<CloudLicensesList />} />
          <Route path="/cloud/nodes" element={<CloudNodes />} />
          <Route path="/cloud/licenses/:username" element={<CloudUserLicensesList />} />
          <Route path="/admin/license/new" element={<NewLicenseForm />} />
          <Route path="/admin/license/playout/new" element={<NewLicenseForm />} />
          <Route path="/qs-hub/hub-channels" element={<HubChannelsList />} />
          <Route path="/virtual-channel/:type/:id" element={<HubChannelDetailView />} />
          <Route path="/node/:cwid/:cnn/channels" element={<NodeDashboard />} />
          <Route path="/node/:cwid/:cnn/system" element={<NodeSystem />} />
          <Route path="/node/:cwid/:cnn/licenses" element={<NodeLicenseList />} />
          <Route path="/node/:cwid/:cnn/channel/edit/:id" element={<NodeChannelEdit />} />
          <Route path="/node/:cwid/:cnn/channel/:id" element={<NodeChannelDetailView />} />
          <Route path="/nodes-management" element={<NodesListView />} />
          <Route path="/node/:cwid/:cnn/manage-access" element={<ShareNodeForm />} />
          <Route path="/notifications" element={<NotificationsPage />} />
        </Route>
      </Route>
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  </HistoryRouter>
);

export default MainRouters;
