import { CONNECTION_METHOD_INPUT, DEFAULT_VALUES, INPUT_URL_TYPE, SELECT_DEFAULT_AUTO } from "../constants";

export const DEFAULT_IN_UDP_UNICAST = {
  type: CONNECTION_METHOD_INPUT.inUdpUnicast.value,
  interfaceIp: SELECT_DEFAULT_AUTO.value,
  // localPort: DEFAULT_VALUES.LOCALPORT_INPUT_UNICAST,
};

export const DEFAULT_IN_UDP_MULTICAST = {
  type: CONNECTION_METHOD_INPUT.inUdpMulticast.value,
  interfaceIp: SELECT_DEFAULT_AUTO.value,
  destinationPort: DEFAULT_VALUES.PORT,
};

export const DEFAULT_IN_SRT_LISTENER = {
  type: CONNECTION_METHOD_INPUT.inSrtListener.value,
  interfaceIp: SELECT_DEFAULT_AUTO.value,
  // localPort: DEFAULT_VALUES.PORT,
  srtLatency: DEFAULT_VALUES.SRT_LATENCY,
  srtOverhead: DEFAULT_VALUES.OVERHEAD,
  encryptionEnabled: false,
  setStreamId: false,
};

export const DEFAULT_IN_SRT_CALLER = {
  ...DEFAULT_IN_SRT_LISTENER,
  type: CONNECTION_METHOD_INPUT.inSrtCaller.value,
  localPort: DEFAULT_VALUES.LOCALPORT,
  destinationAddress: DEFAULT_VALUES.IP,
  destinationPort: DEFAULT_VALUES.PORT,
};

export const DEFAULT_INPUT_BACKUP_VALUES = {
  type: CONNECTION_METHOD_INPUT.inUdpMulticast.value,
  interfaceIp: SELECT_DEFAULT_AUTO.value,
  destinationPort: DEFAULT_VALUES.PORT,
};

export const DEFAULT_INPUT_BACKUP_TRIGGERS = {
  triggerBitrateEnabled: DEFAULT_VALUES.TRIGGER_INIT,
  triggerBitrateLevel: DEFAULT_VALUES.TRIGGER_BITRATE_LEVEL,
  triggerBitrateTimeSecs: DEFAULT_VALUES.TRIGGER_BITRATE_SECS,
  triggerConnectionTimeEnabled: DEFAULT_VALUES.TRIGGER_INIT,
  triggerConnectionTimeSecs: DEFAULT_VALUES.TRIGGER_BITRATE_TIME_SECS,
  triggerDisconnectEnabled: DEFAULT_VALUES.TRIGGER_INIT,
};

const transcodingInputURLDefaultValues = ({ input }) => {
  return {
    ...input,
    type: INPUT_URL_TYPE.value,
    videoStreamSelector: {
      streamId: SELECT_DEFAULT_AUTO.value,
    },
    audioStreamSelectors: [
      {
        streamId: SELECT_DEFAULT_AUTO.value,
      },
    ],
    inputStreamCfg: {
      ...input?.inputStreamCfg,
      type: input?.inputStreamCfg?.type || CONNECTION_METHOD_INPUT.inUdpMulticast.value,
      interfaceIp: input?.inputStreamCfg?.interfaceIp || SELECT_DEFAULT_AUTO.value,
      destinationPort: input?.inputStreamCfg?.destinationPort || DEFAULT_VALUES.PORT,
    },
    backupTriggers: input?.backupTriggers || DEFAULT_INPUT_BACKUP_TRIGGERS,
  };
};

export default transcodingInputURLDefaultValues;
