/* eslint-disable max-len */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { defineMessages, useIntl } from "react-intl";
import { Row, Col, Divider, Card, Form } from "antd";
import styled from "styled-components";
import { connect } from "react-redux";
import ImmutablePropTypes from "react-immutable-proptypes";

import { selectors as NODE_SELECTORS } from "../../../ducks/node";
import { selectors as CLOUD_CHANNELS_SELECTORS } from "../../../ducks/cloudChannels";
import { selectors as CHANNEL_SELECTORS } from "../../../ducks/nodeChannels";

import CloudChannelsServices from "../../../services/cloudChannels";

import {
  CONNECTION_METHOD_OUTPUT,
  QUICKSTREAM_METHOD_V2,
  DEMO_CONNECTION_METHOD,
  OUTPUTS_TYPE,
} from "../../../lib/utils/constants";
import { max32characters, az09space, required } from "../../../lib/utils/formValidation";
import { errorNotification } from "../../../lib/utils/notification";
import { OUTPUT_TYPE } from "../../../lib/utils/types/outputURLTypes";

import { PortField } from "../../Fields/Fields";
import OutputConnectionMethod from "../../Fields/OutputConnectionMethod";
import OutputStreamUdpSection from "../OutputStreamSection/OutputStreamUdpSection";
import OutputStreamSrtSection from "../OutputStreamSection/OutputStreamSrtSection";
import CloudChannelList from "../../Fields/CloudChannelList";
import InputTextFiled from "../../Fields/InputTextField";
import InputNumberField from "../../Fields/InputNumberField";
import OutputStreamQSSection from "../../InputStreamQSSection";
import DemoConnectionMethod from "../../DemoConnectionMethod";
import RemoveButton from "../../RemoveButton";
import LocalPortLabel from "../../LocalPortLabel";
import AdvancedSettings from "../../AdvancedSettings";

const SRT_METHODS = [CONNECTION_METHOD_OUTPUT.outSrtCaller.value, CONNECTION_METHOD_OUTPUT.outSrtListener.value];
const UDP_METHODS = [CONNECTION_METHOD_OUTPUT.outUdp.value];

const translations = defineMessages({
  cloudIdLabel: {
    id: "NonmuxedSectionFormItem.cloudIdLabel",
    defaultMessage: "Cloud ID",
  },
  outputName: {
    id: "NonmuxedSectionFormItem.outputName",
    defaultMessage: "Output name",
  },
  cloudName: {
    id: "NonmuxedSectionFormItem.cloudName",
    defaultMessage: "Cloud name",
  },
  outputSection: {
    id: "NonmuxedSectionFormItem.outputSection",
    defaultMessage: "Output",
  },
  auto: {
    id: "general.auto",
    defaultMessage: "Auto",
  },
  automatic: {
    id: "general.automatic",
    defaultMessage: "Automatic",
  },
  confirmRemoveText: {
    id: "NonmuxedSectionFormItem.confirmRemove",
    defaultMessage: "Are you sure, you want to remove this output?",
  },
  nameAlreadyExist: {
    id: "NonmuxedSectionFormItem.nameAlreadyExist",
    defaultMessage: "This output name already exist",
  },
});

const OutputFormItem = ({
  channelId,
  disabled,
  getFieldValue,
  handleRemove,
  index,
  initialValue,
  isMuxedOutput,
  isNonmuxedOutput,
  name,
  nodeChannels,
  outputType,
  ownChannelList,
  prefix,
  setDisableCondition,
  setFieldsValue,
  stundAddress,
  form,
}) => {
  const { formatMessage } = useIntl();
  const formConnectionMethod = Form.useWatch([...prefix, index, "type"], form);

  useEffect(() => {
    CloudChannelsServices.getOwnChannels({ errorNotification: errorNotification(formatMessage) });
    CloudChannelsServices.getSharedChannels({ errorNotification: errorNotification(formatMessage) });
  }, [formatMessage]);

  const DEMO_CONNECTION_METHOD_VALUE_ARRAY = DEMO_CONNECTION_METHOD.map((method) => method.value);
  const isSRTMethod = SRT_METHODS.includes(formConnectionMethod);
  const isUDPMethod = UDP_METHODS.includes(formConnectionMethod);
  const isQuickstreamMethod = formConnectionMethod === QUICKSTREAM_METHOD_V2.outQSDirect.value;

  const isDemoConnectionMethod = DEMO_CONNECTION_METHOD_VALUE_ARRAY.includes(formConnectionMethod);
  const selectedConnectionMethodLabel = DEMO_CONNECTION_METHOD.find((method) => method.value === formConnectionMethod);

  const handleChangeConnectionMethod = (value) => {
    const idDemoConnectionMethodSelected = Object.values(value).includes(DEMO_CONNECTION_METHOD[0].value);
    setDisableCondition(idDemoConnectionMethodSelected);
  };

  const uniqueValidator = (rule, value) => {
    const outputNames = nodeChannels.getIn([`${channelId}`, "outputs", "outputsNames"]);

    const parsedOutputNames = outputNames && outputNames.filter((outName) => outName !== initialValue?.outputName);
    const duplicatedNonmuxedOutputName = parsedOutputNames && parsedOutputNames.includes(value);

    if (duplicatedNonmuxedOutputName) {
      return Promise.reject(formatMessage(translations.nameAlreadyExist));
    }

    return Promise.resolve();
  };

  return (
    <StyledCard
      title={initialValue && initialValue?.outputName}
      extra={
        name &&
        !disabled && (
          <RemoveButton
            handleRemove={handleRemove}
            fieldName={name}
            confirmRemoveText={formatMessage(translations.confirmRemoveText)}
          />
        )
      }
    >
      <Row type="flex" justify="start" gutter={24}>
        <Col span={12}>
          <OutputConnectionMethod
            name={[...prefix, index, "type"]}
            disabled={disabled}
            connectionMethod={formConnectionMethod}
            setConnectionMethod={handleChangeConnectionMethod}
            isMuxedOutput={outputType === OUTPUTS_TYPE.muxedOutputs}
            isNonMuxedOutput={outputType === OUTPUTS_TYPE.nonMuxedOutputs}
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
            stundAddress={stundAddress}
            prefix={[...prefix, index, "type"]}
          />
        </Col>
        <Col span={12}>
          <InputTextFiled
            disabled={disabled}
            name={[...prefix, index, "outputName"]}
            label={formatMessage(translations.outputName)}
            rules={[{ validator: uniqueValidator }, max32characters, az09space, required]}
          />
        </Col>
        {initialValue?.outputId && (
          <StyledHiddenCol span={4}>
            <InputNumberField name={[...prefix, index, "outputId"]} label="outputId" disabled={disabled} />
          </StyledHiddenCol>
        )}
        <Divider />
        {isDemoConnectionMethod && <DemoConnectionMethod label={selectedConnectionMethodLabel.label} />}
        {isQuickstreamMethod && (
          <>
            <Col span={16}>
              <CloudChannelList
                channelList={ownChannelList}
                disabled={disabled}
                getFieldValue={getFieldValue}
                initialValue={
                  initialValue?.cloudId && initialValue?.cloudName
                    ? {
                        cloudId: initialValue?.cloudId,
                        channelName: initialValue?.cloudName,
                        permissionId: initialValue?.permissionId,
                        description: initialValue?.description,
                      }
                    : null
                }
                name={[...prefix, index]}
                label={formatMessage(translations.cloudIdLabel)}
                sameAsValidation="input"
                setFieldsValue={setFieldsValue}
                isOutput={isNonmuxedOutput}
                isMuxedOutput={isMuxedOutput}
              />
            </Col>
            <Col span={8}>
              <PortField
                name={[...prefix, index, "localPort"]}
                disabled={disabled}
                label={<LocalPortLabel noTooltip />}
                min={1}
              />
            </Col>
            <StyledHiddenCol span={4} label={formatMessage(translations.cloudName)}>
              <InputTextFiled name={[...prefix, index, "cloudName"]} disabled={disabled} />
            </StyledHiddenCol>
          </>
        )}
        {isSRTMethod && (
          <OutputStreamSrtSection
            disabled={disabled}
            name={[index]}
            prefix={[...prefix, index]}
            connectionMethod={formConnectionMethod}
            initialValue={initialValue}
            setFieldsValue={setFieldsValue}
            getFieldValue={getFieldValue}
            form={form}
          />
        )}
        {isUDPMethod && (
          <OutputStreamUdpSection prefix={[...prefix, index]} initialValue={initialValue} disabled={disabled} />
        )}
        {isQuickstreamMethod && (
          <AdvancedSettings>
            <OutputStreamQSSection
              disabled={disabled}
              name={[...prefix, index]}
              prefix={[...prefix, index]}
              initialValue={initialValue}
              setFieldsValue={setFieldsValue}
              getFieldValue={getFieldValue}
              isNonmuxedOutput={isNonmuxedOutput}
              isMuxedOutput={isMuxedOutput}
              form={form}
            />
          </AdvancedSettings>
        )}
      </Row>
    </StyledCard>
  );
};

const StyledHiddenCol = styled(Col)`
  visibility: hidden;
  display: none;
`;

const StyledCard = styled(Card)`
  margin-top: 10px;
`;

OutputFormItem.propTypes = {
  channelId: PropTypes.string,
  disabled: PropTypes.bool,
  getFieldValue: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  initialValue: PropTypes.shape(OUTPUT_TYPE),
  // isMuxedOutput: PropTypes.bool,
  // isNonmuxedOutput: PropTypes.bool,
  name: PropTypes.string,
  nodeChannels: ImmutablePropTypes.map.isRequired,
  // ownChannelList: PropTypes.oneOfType([
  //   PropTypes.arrayOf(
  //     PropTypes.shape({
  //       cloudId: PropTypes.number,
  //       channelName: PropTypes.string.isRequired,
  //       id: PropTypes.number.isRequired,
  //       isEncrypted: PropTypes.bool.isRequired,
  //       isPublic: PropTypes.bool.isRequired,
  //       userId: PropTypes.string.isRequired,
  //     })
  //   ),
  //   PropTypes.array,
  // ]).isRequired,
  outputType: PropTypes.string,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  setDisableCondition: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  stundAddress: PropTypes.string,
};

OutputFormItem.defaultProps = {
  channelId: null,
  disabled: null,
  initialValue: null,
  // isMuxedOutput: null,
  // isNonmuxedOutput: null,
  name: null,
  outputType: null,
  stundAddress: null,
};

const mapStateToProps = (state) => ({
  nodeChannels: CHANNEL_SELECTORS.getChannels(state),
  ownChannelList: CLOUD_CHANNELS_SELECTORS.getOwnChannels(state),
  stundAddress: NODE_SELECTORS.getStundAddress(state),
});

export default connect(mapStateToProps, null)(OutputFormItem);
